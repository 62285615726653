<template>
	<div style="opacity: 0;display:none;" id="text_to_audio_az6hmn" @click="openTextToAudioPopupBtn"></div>
	<a-modal wrapClassName="text_to_audio_warp_ji1x" v-model:visible="visible" :destroyOnClose="true" title="文字合成语音" @ok="handleOk" okText="确认" cancelText="取消" width="900px">
		<SpeechSynthesis @differentiation="differentiation" :info="info" ref="SpeechSynt"></SpeechSynthesis>
	</a-modal>
</template>

<script>
	import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
	import { createVNode, defineComponent } from 'vue';
	import { Modal } from 'ant-design-vue';
	import SpeechSynthesis from '../../components/SpeechSynthesis.vue';
	export default {
		name: 'texttospeech',
		components: {
			SpeechSynthesis,
		},
		data() {
			return {
				visible:false,
				info:{
					id:'',
				},
			}
		},
		mounted() {
		},
		methods: {
			openTextToAudioPopupBtn(){
				if(typeof text_to_audio_popup_info !='undefined' && text_to_audio_popup_info){
					this.info=JSON.parse(JSON.stringify(text_to_audio_popup_info))
				}
				this.visible = true;
			},
			handleOk(){
				var that =this;
				var returan_data= this.$refs.SpeechSynt.handleCreate();
				if(returan_data.state){
					Modal.confirm({
						title: () => '语音提示',
						icon: () => createVNode(ExclamationCircleOutlined),
						content: () => '还未生成语音，如果需要合成语音请先点击生成语音按钮，如果不需要直接点击放弃',
						okText:'放弃',
						cancelText:'取消',
						onOk() {
							if(typeof textToAudioPopupCallbackHuhs2d == 'function'){
								textToAudioPopupCallbackHuhs2d(returan_data)
							}
							that.visible = false;
						},
						onCancel() {},
					  });
				}else{
					if(typeof textToAudioPopupCallbackHuhs2d == 'function'){
						textToAudioPopupCallbackHuhs2d(returan_data)
					}
					this.visible = false;
				}
				/*
				返回格式说明
				{
					id: 12092,//记录附件id
					fileId: 12092,//同id
					file_openid: "63073b828d03d",
					filename: "文字合成语音.mp3",
					name: "文字合成语音.mp3",
					filesize: "837.68 KB",
					filesize_byte: 857786,
					height: 0,
					width: 0,
					duration: 107.18,//音频时长
					old_transcode_url: "//view-nc.yasuoxia.com/2022/06/17/ILAOPPDM_GFMF_4PMG_IMID_AJDFHMMDAOLC.mp3",//原始文件
					low_transcode_url: "//view-nc.yasuoxia.com/2022/06/17/ILAOPPDM_GFMF_4PMG_IMID_AJDFHMMDAOLC.H_25_240.mp3",//转码后低清
					transcode_url: "//view-nc.yasuoxia.com/2022/06/17/ILAOPPDM_GFMF_4PMG_IMID_AJDFHMMDAOLC.H_25_1.mp3",//转码后高清
					media_type: "audio/mp3",
					t_media_type: "audio/mp3",
					media_types_a: "audio",
					media_types_b: "mp3",
					residue_available: 5,
					type: "audio",
					speech_log_id:"",//文字合成语音记录表id
				}*/

			},
			differentiation(data){
				this.$baseFunc.checkState(data).then(res => {
					}).catch(err=>{
					});
			}
		}
	}
</script>

<style>
	.text_to_audio_warp_ji1x .ant-modal-body{padding: 24px;}
</style>
