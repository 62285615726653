<template>
	<div class="container"> 	
		<div class="audio_style" v-show="audio.AudioStatus">
			 <a-spin style="margin-top: 35%;" size="large" tip="语音合成中..."/>
		</div>
		<div class="audio_style" v-show="edit_loading">
			 <a-spin style="margin-top: 35%;" size="large" tip="加载中..."/>
		</div>
		<div class="outOptions">
			<div class="outoptionsItem">
				<img :src="anchor.AnchorStatus.avatar">
				<span class="outoptionsItem-name">合成主播</span>
				 <a-dropdown :trigger="['hover']" v-model:visible="anchor.open">
					 <a-button class="outoptions-default-dropdown" style="width: 95px;">
						 <text>{{anchor.AnchorStatus.label}}</text>
						 <span class="outoptions-default-dropdown-icon">
						 	<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10"><path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path></svg>
						 </span>
					 </a-button>
					 <template #overlay>
					 	<div class="select_hczb" style="position: relative;">
					 		<div class="layui-tab layui-tab-brief hczbTab">
					 			<!-- 加载动画 -->
					 			<div class="example an_type" v-show="loading">
					 				<a-spin style="margin-top: 2%;" />
					 			</div>
					 			<a-tabs @tabClick="anchor.AnchorGroupStatus" style="min-height: 45px;" destroyInactiveTabPane>
					 				<a-tab-pane :key="item.id" :tab="item.name" v-for="(item,index) in anchor.AnchorGroup">
					 					<!-- 加载动画 -->
					 					<div class="example an_list" v-show="An_loading">
					 						<a-spin style="margin-top: 24%;" />
					 					</div>
					 					<div class="layui-tab-content" style="height: 300px;overflow: auto;">
					 						<div class="layui-tab-item layui-show">
					 							<ul class="hczbTabItem">
					 								<li class="hczbItem" v-for="Listitem of anchor.AnchorList"
					 									@click="anchor.AnchorListChecked(Listitem)">
					 									<img :src="Listitem.avatar" class="nuwccardimg">
					 									<span>{{Listitem.name}}</span>
					 								</li>
					 							</ul>
					 						</div>
					 					</div>
					 				</a-tab-pane>
					 			</a-tabs>
					 		</div>
					 	</div>
					 </template>
				 </a-dropdown>

			</div>
			<div class="outoptionsItem">
				<span class="outoptionsItem-name">音量</span>
				<a-select style="width: 95px;" :value="volume.VolumeStatus" label-in-value :options="volume.VolumeOptions" @change="volume.VolumeChangeSelect">
					<template #suffixIcon>
						<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10"><path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path></svg>
					</template>
				</a-select>
			</div>
			<div class="outoptionsItem">
				<span class="outoptionsItem-name">语速</span>
				<a-select style="width: 95px;" :value="speech.SpeechStatus" label-in-value  :options="speech.SpeechOptions" @change="speech.SpeechChangeSelect">
					<template #suffixIcon>
						<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10"><path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path></svg>
					</template>
				</a-select>
			</div>
			<div class="outoptionsItem">
				<span class="outoptionsItem-name">语调</span>
				<a-select style="width: 95px;" :value="pitch.PitchStatus" label-in-value  :options="pitch.PitchOptions" @change="pitch.PitchhChangeSelect">
					<template #suffixIcon>
						<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10"><path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path></svg>
					</template>
				</a-select>
			</div>
			<div class="outoptionsItem" style="position: relative;">
				<span class="outoptionsItem-name">背景音乐</span>
				<a-dropdown :trigger="['hover']" v-model:visible="bgm.open">
									 <a-button class="outoptions-default-dropdown" style="width: 120px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
										 <text>{{bgm.BgmStatus.label}}</text>
										 <span class="outoptions-default-dropdown-icon">
										 	<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7991" width="10" height="10"><path d="M946.4 353.50000001l-419.9 419.9c-8.2 8.2-21.5 8.2-29.7 0l-421.1-421.1c-16.4-16.4-16.4-43 0-59.3 16.4-16.4 43-16.4 59.3 0L504.2 662.20000001c4.1 4.1 10.7 4.1 14.8 0l368-368c16.4-16.4 43-16.4 59.3 0 16.5 16.3 16.5 42.9 0.1 59.3z" fill="#8a8a8a" p-id="7992"></path></svg>
										 </span>
									 </a-button>
					<template #overlay>				 
						<div class="select_bgm">
							<div class="layui-tab layui-tab-brief hczbTab">
								<!-- 加载动画 -->
								<div class="example bgm_type" v-show="loading">
									<a-spin style="margin-top: 2%;" />
								</div>
								<a-tabs @tabClick="bgm.BgmGroupStatus" style="min-height: 280px;" destroyInactiveTabPane>
									<a-tab-pane :key="item.id" :tab="item.name" v-for="(item,index) in bgm.BgmGroup">
										<!-- 加载动画 -->
										<div class="example bgm_list" v-show="An_loading">
											<a-spin style="margin-top: 24%;" />
										</div>
										<div class="layui-tab-content choose-bgm" style="height: 280px;">
											<div class="layui-tab-item layui-show ">
												<ul class="bgmTabItem">
													<li v-for="Bgmitem of bgm.BgmList" @click="bgm.BgmListChecked(Bgmitem)">
														<PlayCircleTwoTone two-tone-color="#09bd71" 
															style="margin: auto;padding-right: 25px;width:20px;height:20px;" />
														<span class="bgm-title"
															style="margin: auto;">{{Bgmitem.title}}</span>
													</li>
												</ul>
											</div>
										</div>
									</a-tab-pane>
								</a-tabs>
								<div class="bgm_slider" style="height: 50px;display: flex;">
									<div style="margin: auto;">
										<span>背景音量：低</span>
									</div>
									<div style="width: 160px; margin: auto;">
										<a-slider v-model:value="bgm.bgm_value" :max="100" :min="1"
											@afterChange="bgm.SliderChange"></a-slider>
									</div>
									<div style="margin: auto;">
										<span>高</span>
									</div>
									<a-button v-show="bgm.BgmStatus.id !=0" style="margin: auto;" type="primary"
										@click="bgm.CancelBgm">取消背景音乐</a-button>
								</div>
							</div>
						</div>					
					</template>
				</a-dropdown>	
			</div>
		</div>
		<div class="mainContainer">
			<div class="mainitem">
				<div class="main-options">
					<a-popover  trigger="hover">
					      <template #content>
					        <p style="color:#09bd71">点击该按钮纠正多音字发音</p>
					      </template>
					     <div class="main-options-item" @click="polyphony.click_polyphony" style="position: relative;">
					     	<div class="handle__icon__img__jushx py"><img src="/img/texttospeech/icon-annotaion@2x.png"/></div>
					     	<p>多音字</p>
					     	<a-modal forceRender :zIndex="1013" v-model:visible="polyphony.polyphonyStatus" width="800px" title="点击需要纠正的多音字，选择正确的发音"
					     		@ok="polyphony.submit_polyphony" okText="确认" cancelText="取消" @cancel="polyphony.cancel_polyphony">
					     			<div id="py-dialog-content" class="py-dialog-content">
					     				<div class="tts-content-paragraph">
					     				<span v-for="(item,index) in polyphony.contentData">
					     					<a-popover v-if="item.type==1" v-model:visible="item.visible" trigger="click" placement="bottomLeft">
					     						<template #content>
					     							<div class="pinyin-items-wrapper">
					     								<div class="pinyin-item-ttsmark " @click.stop="polyphony.submitBuhc(index,itemIndx)"  :data-pinyin="item.py.py_number[itemIndx]" v-for="(itemChild,itemIndx) in item.py.py_tone">{{itemChild}}</div>
					     								<div class="pinyin-item-ttsmark pinyin-item-cancel" @click.stop="polyphony.closesahudhasi(index,itemIndx)">取消</div>
					     							</div>
					     						</template>
					     						<i class="tts-mark-flag tts-mark-point abcd tts-mark-annotation _active" style="color:#ff0000;cursor: pointer;" contenteditable="false" :data-tts="item.checkPy" :data-label="item.checkPy" data-type="annotation">{{item.text}}</i>
					     					</a-popover>
					     					<text v-else>{{item.text}}</text>
					     				</span>
					     				</div>
					     			</div>
					     			<div class='pysss' id='pysss' ref='pysss' >
									</div>
					     	</a-modal>
					     </div>
					</a-popover>
						
					<a-popover  trigger="hover">
					      <template #content>
					        <p style="color:#09bd71">选中数字文本后点击下方按钮选择读法</p>
					      </template>
							<div class="main-options-item read_number">
								<div class="handle__icon__img__jushx number"><img src="/img/texttospeech/icon-number@2x.png"/></div>
								<p>数字</p>
								<div class="number_block">
									<div class="tts-item" @click="number.ReadNumbers(1)">读数字</div>
									<div class="tts-item read_value" @click="number.ReadNumbers(2)">读数值</div>
								</div>
							</div>
					</a-popover>
					<a-popover  trigger="hover">
					      <template #content>
					        <p style="color:#09bd71">点击该按钮当前光标前后两字间断句</p>
					      </template>
						<div class="main-options-item" @click="punctuation.BrokenSentences">
							<div class="handle__icon__img__jushx punct"><img src="/img/texttospeech/icon-segmentation@2x.png"/></div>
							<p>断句</p>
						</div>
					    </a-popover>

					
					<a-popover  trigger="hover">
					      <template #content>
					        <p style="color:#09bd71">点击该按钮当前光标前后两字连读</p>
					      </template>
						<div class="main-options-item" @click="continuity.Continuity">
							<div class="handle__icon__img__jushx contin"><img src="/img/texttospeech/icon-continuity@2x.png"/></div>
							<p>连续</p>
						</div>
					    </a-popover>

					
					<a-popover  trigger="hover">
					      <template #content>
					        <p style="color:#09bd71">点击下方按钮在当前光标处插入停顿</p>
					      </template>
						<div class="main-options-item pasue_number">
							<div class="handle__icon__img__jushx pasue"><img src="/img/texttospeech/icon-pause@2x.png"/></div>
							<p>停顿</p>
							<div class="number_block tts-pause-items">
								<div class="tts-item" @click="pause.Pause(0.5)">0.5秒</div>
								<div class="tts-item" style="border-top: 1px solid rgb(229, 229, 229)"
									@click="pause.Pause(1)">1秒</div>
								<div class="tts-item" style="border-top: 1px solid rgb(229, 229, 229)"
									@click="pause.Pause(2)">2秒</div>
							</div>
						</div>
					    </a-popover>

					
					<a-popover  trigger="hover">
					      <template #content>
					        <p style="color:#09bd71">选中文本后点击该按钮调节局部音量</p>
					      </template>
						<div class="main-options-item local_volume">
							<div class="handle__icon__img__jushx volume"><img src="/img/texttospeech/icon-volume@2x.png"/></div>
							<p>局部音量</p>
							<div class="currency_slider volume_slider">
								<CaretUpFilled class="FontColorsOutlined" style="color: #09bd71;" />
								<div class="low_style">
									<span>低</span>
								</div>
								<div style="width: 160px; margin: auto;">
									<a-slider v-model:value="volume.volume_value" :max="100" :min="1"
										@afterChange="volume.VolumeChange"></a-slider>
								</div>
								<div class="height_style">
									<span>高</span>
								</div>
								<div class="style_button" @click="volume.volume_button">
									<span>确认</span>
								</div>
							</div>
						</div>
					    </a-popover>

					
					<a-popover  trigger="hover">
					      <template #content>
					        <p style="color:#09bd71">选中文本后点击该按钮调节局部变速</p>
					      </template>
						<div class="main-options-item local_rate">
							<div class="handle__icon__img__jushx rate"><img src="/img/texttospeech/icon-speed@2x.png"/></div>
							<p>局部变速</p>
							<div class="currency_slider rate_slider">
								<CaretUpFilled class="FontColorsOutlined" style="color: #09bd71;" />
								<div class="low_style">
									<span>低</span>
								</div>
								<div style="width: 160px; margin: auto;">
									<a-slider v-model:value="speech.rate_value" :max="100" :min="1"
										@afterChange="speech.RateChange"></a-slider>
								</div>
								<div class="height_style">
									<span>高</span>
								</div>
								<div class="style_button" @click="speech.rate_button">
									<span>确认</span>
								</div>
							</div>
						</div>
					    </a-popover>

					
					<a-popover  trigger="hover">
					      <template #content>
					        <p style="color:#09bd71">选中文本后点击该按钮调节局部变调</p>
					      </template>
						<div class="main-options-item local_pitch">
							<div class="handle__icon__img__jushx pitch"><img src="/img/texttospeech/icon-intonation@2x.png"/></div>
							<p>局部变调</p>
							<div class="currency_slider pitch_slider">
								<CaretUpFilled class="FontColorsOutlined" style="color: #09bd71;" />
								<div class="low_style">
									<span>低</span>
								</div>
								<div style="width: 160px; margin: auto;">
									<a-slider v-model:value="pitch.pitch_value" :max="100" :min="1"
										@afterChange="pitch.PitchChange"></a-slider>
								</div>
								<div class="height_style">
									<span>高</span>
								</div>
								<div class="style_button" @click="pitch.pitch_button">
									<span>确认</span>
								</div>
							</div>
						</div>
					    </a-popover>

					<div class="main-options-back tts-undo" @click="revoke.tts_undo" :style="{cursor:revoke.ttsUndo.cursor}">
						<div class="main-options-item-bgimgL" :style="{opacity:revoke.ttsUndo.opacity}"></div>
						<p>撤回</p>
					</div>
					<div class="main-options-reset tts-redo" @click="revoke.tts_redo" :style="{cursor:revoke.ttsRedo.cursor}">
						<div class="main-options-item-bgimgR" :style="{opacity:revoke.ttsRedo.opacity}"></div>
						<p>重做</p>
					</div>
				</div>
				<div class="maintextarea con" :class="[editor.no_after?'no-after':'']" id="maintextarea" style="resize: none;overflow: auto;margin: 0px;border: 0px;height: 298px;padding: 20px;text-align: initial;width: 100%;position: relative;" 
					empty="true" 
					ref="edit" 
					contenteditable="true"
					@input="editor.hetext" @mouseup.stop="editor.getSelectedContent($event)" v-html="value_text" 
					@blur="editor.handleInputBlur($event)"
					>
				</div>
				<div class="audioStyle">
					<div v-show="audio.AudioDisplay">
						<div class="mbm_audio_box">
							<div class="mbm_progress_box">
								<div class="mbm_audio_playerBtn" @click="audio.audioPlayPause">
									<img v-if="audio.isPlay" class="mbm_playerBtn" src="/img/play.png" />
									<img v-if="!audio.isPlay" class="mbm_playerBtn" src="/img/pause.png" />
								</div>
								<a-slider strokeWidth="2" class="text_hu1x812_slider" :tooltipVisible="false" :min="0" :max="audio.info.duration" v-model:value="audio.info.currentTime"
									 @change="audio.sliderChange" @afterChange="audio.sliderAfterChange" />
								<div class="mbm_audio_timeBox">
									<span>{{audio.info.realMusicTime}}</span>/
									<span>{{audio.info.totalMusicTime}}</span>
								</div>
							</div>
					
						</div>
						<audio ref="music" :src="audio.info.t_url" preload="auto">
						</audio>
					</div>	
					<p class="textlength-tips" v-html="ts_msg"></p>
				</div>
			</div>
		</div>
		
		<div class="buttoncontainer">
			<a-button type="primary" size="large" style="width: 180px;margin: 0 10px;" @click="GenerateVoice">生成语音</a-button>
			<a-button size="large" style="width: 180px;margin: 0 10px;" @click="DownloadVoice">下载语音</a-button>
		</div>
	</div>
	
</template>

<script>
	import $ from "jquery";
	import {
		PlayCircleTwoTone,
		FontColorsOutlined,
		CaretUpFilled
	} from '@ant-design/icons-vue';
	import base_func from '../config/base_func.js';
	import config from '../config/config.js'//
	import pyMaps from '../config/py-map.js'
	import tts_Editor from '../config/ttsEditor.js'
	import baseFunc from '../config/base_func.js'
	import axios from "axios";
	import {
		Empty,
		Modal,
		message,
	} from 'ant-design-vue';
	export default {
		name: 'SpeechSynthesis',
		props: {
			info: Object,
		},
		components: {
			PlayCircleTwoTone,
			FontColorsOutlined,
			CaretUpFilled
		},
		data() {
			return {
				_undoStack:[],
				_redoStack:[],
				_emitter:null,
				PinyinMap:pyMaps,
				PinyinKey:Object.keys(pyMaps),
				loading: true, 
				An_loading: true, 
				edit_loading:false,
				/*语音合成最大值*/
				default_max_lenth: 2000, 
				max_lenth: 0,
				min_lenth: 0,
				Return_data:{
					state:false,
					code:'',
					message:'',
					data:'',
				},
				successStatus:false,
				ts_msg:'',
				old_text:'',
				 /*文本内容*/
				value_text: "",
				/*选中内容*/
				position: {
					content: '',
					start: 0,
					end: 0,
				},
				/*音频*/
				audio:{
					AudioStatus:false,
					AudioDisplay:false,
					isPlay: true,
					info:{
						filename:"",
						t_url:'',
						realMusicTime:'00:00',
						totalMusicTime:'',
						currentTime:0,
						duration:0,
					},
					/*音乐暂停，播放*/
					audioPlayPause:()=>{
						let that = this;
						if (that.$refs.music.paused) {
							that.$refs.music.play();
						} else {
						  	that.$refs.music.pause();
						}
					},
					/*秒=>时:分:秒*/
					formatSeconds:(value)=> {
						let result = parseInt(value)
						let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
						let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result /
							60 % 60));
						let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
					
						let res = '';
						if (h !== '00') res += `${h}:`;
						res += `${m}:`;
						res += `${s}`;
						return res;
					},
					//滑块值改变
					sliderChange:(value)=> {
						this.$refs.music.currentTime = value;
						this.audio.info.realMusicTime=this.audio.formatSeconds(value);
					},
					//滑块值改变后
					sliderAfterChange:(value)=> {
						this.$refs.music.play();
					},
					// 处理时间
					handlMusicTime:()=> {
						//用秒数来显示当前播放进度
						let timeDisplay = Math.floor(this.$refs.music.currentTime); //获取实时时间
						//分钟
						let minute = parseInt(timeDisplay / 60);
						if (minute < 10) {
							minute = "0" + minute;
						}
						//秒
						let second = Math.round(timeDisplay % 60);
						if (second < 10) {
							second = "0" + second;
						}
						this.audio.info.currentTime = timeDisplay;
						this.audio.info.realMusicTime = minute + ":" + second;
					},
				},
				/*主播*/
				anchor: { 
					active: 1, //主播默认选中
					open:false,
					AnchorStatus: { //主播默认值
						key: 1,
						label: '小云',
						avatar: 'http://peiyinwa.oss-cn-shenzhen.aliyuncs.com/img/anchor/Xiaoyun.jpg',
					},
					copy_AnchorStatus:{},
					AnchorGroup: '', //主播类型
					AnchorList: '', //主播列表
					/*主播分类切换*/
					AnchorGroupStatus: (id) => {
						this.anchor.AnchorList = '';
						this.An_loading = true;
						this.anchor.active = id;
						this.anchor.getAnchorsList();
					},
					/*主播选择*/
					AnchorListChecked: (data) => {
						this.anchor.AnchorStatus = {
							value: data.id,
							label: data.name,
							avatar: data.avatar,
						};
						this.anchor.open=false;
					},
					/*主播分类*/
					getAnchorsType: () => {
						this.$apiRequest.post("/voice/getAnchorsType", {}).then(res => {
							this.anchor.AnchorGroup = res.data.data;
							this.loading = false;
							this.anchor.getAnchorsList();
						}).catch(err => {
							message.error(err.data.message)
						});
					},
					/*主播列表*/
					getAnchorsList: () => {
						this.$apiRequest.post("/voice/getAnchorsList", {
							type_id: this.anchor.active
						}).then(res => {
							this.anchor.AnchorList = res.data.data.data;
							this.An_loading = false;
						}).catch(err => {
							message.error(err.data.message)
						});
					},
				},
				/*背景音乐*/
				bgm: { 
					bgm_active: 1, //背景音乐默认选中
					bgm_value: 50, //背景音乐默认值
					copy_bgm_value:{},
					open:false,
					BgmStatus: {
						key: 0,
						label: '无背景音乐',
						bgm_url: '',
					},
					copy_BgmStatus:{},
					BgmGroup: '',
					BgmList: '',
					/*背景音乐分类*/
					getBgmType: () => {
						this.$apiRequest.post("/voice/getBgmType", {}).then(res => {
							this.bgm.BgmGroup = res.data.data;
							this.loading = false;
							this.bgm.getBgmList();
						}).catch(err => {
							message.error(err.data.message)
						});
					},
					/*背景音乐列表*/
					getBgmList: () => {
						this.$apiRequest.post("/voice/getBgmList", {
							type_id: this.bgm.bgm_active
						}).then(res => {
							this.bgm.BgmList = res.data.data.data;
							this.An_loading = false;
						}).catch(err => {
							message.error(err.data.message)
						});
					},
					/*背景音乐分类切换*/
					BgmGroupStatus: (id) => {
						this.bgm.BgmList = '';
						this.An_loading = true;
						this.bgm.bgm_active = id;
						this.bgm.getBgmList();
					},
					/*背景音乐选择*/
					BgmListChecked: (data) => {
						this.bgm.BgmStatus = {
							key: data.id,
							label: data.title,
							bgm_url: data.mp3,
						};
						this.bgm.open = false;
					},
					/*取消背景音乐*/
					CancelBgm: () => {
						this.bgm.BgmStatus = {
							key: 0,
							label: '无背景音乐',
							bgm_url: '',
						};
						this.bgm.open = false;
						this.bgm.bgm_value = 50;
					},
					SliderChange: (value) => {
						this.bgm.bgm_value = value;
					},
				},
				/*音量*/
				volume: { 
					volume_value: 50, //局部音量默认值
					VolumeStatus: { //音量默认值
						key: 70,
						label: '70 默认'
					},
					copy_VolumeStatus:{},
					VolumeOptions: [{ //音量下拉选项
							value: 100,
							label: 100
						},
						{
							value: 95,
							label: 95
						},
						{
							value: 90,
							label: 90
						},
						{
							value: 85,
							label: 85
						},
						{
							value: 80,
							label: 80
						},
						{
							value: 75,
							label: 75
						},
						{
							value: 70,
							label: '70 默认'
						},
						{
							value: 65,
							label: 65
						},
						{
							value: 60,
							label: 60
						},
						{
							value: 55,
							label: 55
						},
						{
							value: 50,
							label: 50
						},
						{
							value: 45,
							label: 45
						},
						{
							value: 40,
							label: 40
						},
						{
							value: 35,
							label: 35
						},
						{
							value: 30,
							label: 30
						},
						{
							value: 25,
							label: 25
						},
						{
							value: 20,
							label: 20
						},
						{
							value: 15,
							label: 15
						},
						{
							value: 10,
							label: 10
						},
						{
							value: 5,
							label: 5
						}
					],
					/*音量选择*/
					VolumeChangeSelect: (e) => {
						this.volume.VolumeStatus = {
							label: e.label,
							value: e.value
						}
					},
					VolumeChange: (value) => {
						this.volume.volume_value = value;
					},
					volume_button: () => {
						var data = this.editor.CursorEndPosition;
						if(data.collapsed){
							message.error('请先选中需调节语调的文本');
							return;
						}
						this.wrapperTts(data, {
							  tts: this.volume.volume_value,
							  label: `音量`+this.volume.volume_value,
							  ttsMarkClass: 'tts-mark-volume',
							  markType: 'volume',
						    });
						this.InitialValue();
						this.$refs.edit.dispatchEvent(new Event('input'));
					},
				},
				/*语调*/
				pitch: { 
					pitch_value: 50, //局部变调默认值
					PitchStatus: { //音调默认值
						key: 0,
						label: '0 默认'
					},
					copy_PitchStatus:{},
					PitchOptions: [{ //音调下拉选项
							value: 500,
							label: 500
						},
						{
							value: 400,
							label: 400
						},
						{
							value: 300,
							label: 300
						},
						{
							value: 200,
							label: 200
						},
						{
							value: 100,
							label: 100
						},
						{
							value: 0,
							label: '0 默认'
						},
						{
							value: -100,
							label: -100
						},
						{
							value: -200,
							label: -200
						},
						{
							value: -300,
							label: -300
						},
						{
							value: -400,
							label: -400
						},
						{
							value: -500,
							label: -500
						},
					],
					/*语调选择*/
					PitchhChangeSelect: (e) => {
						this.pitch.PitchStatus = {
							label: e.label,
							value: e.value
						}
					},
					PitchChange: (value) => {
						this.pitch.pitch_value = value;
					},
					pitch_button: () => {
						var data = this.editor.CursorEndPosition;
						if(data.collapsed){
							message.error('请先选中需调节语调的文本');
							return;
						}
						this.wrapperTts(data, {
							  tts: +this.pitch.pitch_value,
							  label: `语调`+this.pitch.pitch_value,
							  ttsMarkClass: 'tts-mark-intonation',
							  markType: 'intonation',
						    });
						this.InitialValue();
						this.$refs.edit.dispatchEvent(new Event('input'));
					},
				},
				/*语速*/
				speech: { 
					rate_value: 50, //局部变速默认值
					SpeechStatus: { //语速默认值
						key: 0,
						label: '0 默认'
					},
					copy_SpeechStatus:{},
					SpeechOptions: [{ //语速下拉选项
							value: 500,
							label: 500
						},
						{
							value: 400,
							label: 400
						},
						{
							value: 300,
							label: 300
						},
						{
							value: 200,
							label: 200
						},
						{
							value: 100,
							label: 100
						},
						{
							value: 0,
							label: '0 默认'
						},
						{
							value: -100,
							label: -100
						},
						{
							value: -200,
							label: -200
						},
						{
							value: -300,
							label: -300
						},
						{
							value: -400,
							label: -400
						},
						{
							value: -500,
							label: -500
						},
					],
					/*语速选择*/
					SpeechChangeSelect: (e) => {
						this.speech.SpeechStatus = {
							label: e.label,
							value: e.value
						}
					},
					RateChange: (value) => {
						this.speech.rate_value = value;
					},
					rate_button: () => {
						var data = this.editor.CursorEndPosition;
						if(data.collapsed){
							message.error('请先选中需调节语调的文本');
							return;
						}
						this.wrapperTts(data, {
							  tts: +this.speech.rate_value,
							  label: `语速`+this.speech.rate_value,
							  ttsMarkClass: 'tts-mark-speed',
							  markType: 'speed',
						    });
						this.InitialValue();
						this.$refs.edit.dispatchEvent(new Event('input'));
					},
				},
				/*多音字*/
				polyphony: {
					polyphonyStatus: false, //多音字弹窗
					contentData:[],
					yuanyinMap : {
									'ā': 'a',
									'á': 'a',
									'ǎ': 'a',
									'ă': 'a',
									'à': 'a',
									"ō": "o",
									"ó": "o",
									"ǒ": "o",
									"ŏ": "o",
									"ò": "o",
									"ē": "e",
									"é": "e",
									"ě": "e",
									"ĕ": "e",
									"è": "e",
									"ī": "i",
									"í": "i",
									"ǐ": "i",
									"ĭ": "i",
									"ì": "i",
									"ū": "u",
									"ú": "u",
									"ǔ": "u",
									"ŭ": "u",
									"ù": "u",
									"ǖ": "v",
									"ǘ": "v",
									"ǚ": "v",
									"ǜ": "v"
								},
					click_polyphony: () => {
						var data = this.$refs.edit.innerText.trim();
						if(!data){
							message.error('请输入配音内容');
							return;
						}
						this.polyphony.ttsPyMarkOpen();
					},
					cancel_polyphony:()=>{
						this.polyphony.polyphonyStatus=false;
					},
					submit_polyphony: () => {
						var pyContentHtml = $(".py-dialog-content").html()
						pyContentHtml = pyContentHtml.replace(/<i[^>]+tts-mark-annotation[^>]+data-tts=""[^>]+>([^>]+)<\/i>/g, "$1")
						var wrapper = document.createElement('div')
						wrapper.innerHTML = pyContentHtml
						$(wrapper).find('.tts-mark-annotation').removeClass('_active').removeAttr('style')
						this.polyphony.setHtml($(wrapper).html())
					},
					closesahudhasi:(index,itemIndx)=>{
						this.polyphony.contentData[index].checkPy="";
						this.polyphony.contentData[index].visible=false;
					},
					submitBuhc:(index,itemIndx)=>{
						this.polyphony.contentData[index].checkPy="[=" + this.polyphony.contentData[index].py.py_number[itemIndx] + "]";
						this.polyphony.contentData[index].visible=false;
					},
					ttsPyMarkOpen:()=>{
						var that=this;
						var PinyinMaps = that.PinyinMap;
						var old_innerHTML =that.$refs.edit.innerHTML;
						if (!old_innerHTML) {
							message.error('请先输入配音文本后重试');
							return;
						}
						//点击多音字时
						$(document).off('click', '.py-dialog-content .tts-mark-annotation');
						$(document).on('click', '.py-dialog-content .tts-mark-annotation', function (event) {
							console.log(event.target);
							var currentHanziItem = $(this);
							var hanziPinyin = currentHanziItem.text() + currentHanziItem.data().tts || '';
							var hz_pin = hanziPinyin.match(/(.+)(\[=.+\])/);
							var curHanzi = hanziPinyin;
							var curPinyin = '';
							if (hz_pin) {
								curHanzi = hz_pin[1];
								curPinyin = hz_pin[2] || '';
							}

							var allPinyins = PinyinMaps[curHanzi] || [];
							var pinyinBtnsHtml = allPinyins.map(function (pinyin) {
								var ttsPy = that.polyphony.transferYuanyin(pinyin);
								var checkedFlag = ("[=" + ttsPy + "]") === curPinyin ? 'checked' : '';
								// 这里的 data-pinyin 是转换后的 字母数字的 标注的语音格式， 不是声调格式了
								return "<div class='pinyin-item-ttsmark " + checkedFlag + "' data-pinyin='" + ttsPy + "'>" + pinyin + "</div>"
							}).join("");
							pinyinBtnsHtml += '<div class="pinyin-item-ttsmark pinyin-item-cancel">取消</div>';
							pinyinBtnsHtml = '<div class="py_mask"></div><div class="pinyin-items-wrapper"><div class="sanjiaoxing"></div>' + pinyinBtnsHtml + '</div>';
							$(document).off('click', ".pinyin-item-ttsmark");
							document.getElementById('pysss').innerHTML = pinyinBtnsHtml;
							document.querySelector('.pysss').setAttribute("style", "display: block");
							document.querySelector('.pinyin-items-wrapper').setAttribute("style", "left:"+(event.target.getBoundingClientRect().x-3)+"px;top:"+(event.target.getBoundingClientRect().top+event.target.getBoundingClientRect().height+10)+"px;");
							$(document).on('click', ".pinyin-item-ttsmark", function (event) {
								// 这里的 data-pinyin 是转换后的 字母数字的 标注的语音格式， 不是声调格式了
								var choosedPY = $(this).data('pinyin');
								if (choosedPY) {
									currentHanziItem.attr('data-label', '[=' + $(this).data('pinyin') + ']').attr('data-tts', '[=' + $(this).data('pinyin') + ']');
								} else {
									currentHanziItem.attr('data-label', '').attr('data-tts', '');
								}
								document.querySelector('.pysss').setAttribute("style", "display: none");
							});
							$(document).on('click', ".pinyin-item-cancel", function (event) {
								document.querySelector('.pysss').setAttribute("style", "display: none");
							});
							$(document).on('click', ".py_mask", function (event) {
								document.querySelector('.pysss').setAttribute("style", "display: none");
							});
						});
						var idx = 0;
						var innerHTML = old_innerHTML.replace(/<i[^>]+tts-mark-annotation[^>]+data-tts="([^"]+)"[^>]+>([^>]+)<\/i>/g, "$2$1")
						// 负向前瞻	匹配后面不满足表达式exp的位置 不匹配data-label 属性中到中文
						innerHTML = innerHTML.replace(/([\u2E80-\u9FFF])(?![^<>]*>)(\[=(\w+\d?)\])?/g, function (chars, hanzi, pytts, py, _idx) {
							if (PinyinMaps[hanzi]) {
								return '<i class="tts-mark-flag tts-mark-point abcd tts-mark-annotation _active" style="color:#ff0000;cursor: pointer;" contenteditable="false" data-tts="' + (pytts || "") + '" data-label="' + (pytts || "") + '" data-type="annotation">'+hanzi+'</i>'
							}
							return chars
						});
						document.getElementById('py-dialog-content').innerHTML = innerHTML;
						this.polyphony.polyphonyStatus=true;
					},
					transferYuanyin:(pinyin)=> {
						var that =this;
						var tone = '5';
						// 支持 多音字库返回拼音为 yuan4 这种标注形式，而非声调符号
						var mtc = (pinyin || "").match(/([^\d]+)(\d)?/);
						if (!mtc) {
							return ;
						}
						pinyin = mtc[1];
						if (!isNaN(mtc[2])) {
							tone = Number(mtc[2])
						}
						pinyin = pinyin.replace(/ā|á|ǎ|ă|à|ō|ó|ǒ|ŏ|ò|ē|é|ě|ĕ|è|ī|í|ǐ|ĭ|ì|ū|ú|ǔ|ŭ|ù|ǖ|ǘ|ǚ|ǜ/, function (char) {
							// a o e i u ü
							if (/ā|ō|ē|ī|ū|ǖ/.test(char)) {
								tone = '1';
							} else if (/á|ó|é|í|ú|ǘ/.test(char)) {
								tone = '2';
							} else if (/ǎ|ă|ǒ|ŏ|ĕ|ě|ǐ|ĭ|ǔ|ŭ|ǚ/.test(char)) {
								tone = '3';
							} else if (/à|ò|è|ì|ù|ǜ/.test(char)) {
								tone = '4';
							}
							// 转换原音字母
							return (that.polyphony.yuanyinMap[char] || char);
						});
						return pinyin + tone;
					},
					  setHtml:(html)=> {
					    this.$refs.edit.innerHTML = html;
					    setTimeout(() => {
					      this.fixBackspace(this.editor.CursorEndPosition);
					      this.$refs.edit.dispatchEvent(new Event('input'));
						  this.polyphony.polyphonyStatus=false;
					    })
					  }
				},
				/*数字*/
				number: {
					ReadNumbers: (num) => {
						var data = this.editor.CursorEndPosition;
						if(data.collapsed){
							message.error('请先选中需要指定读法的文本');
							return;
						}
						if (!!data.innerText.match(/\d/g)) {
							let label = '';
							if (num == 1) { //读数字
								label = '读数字';
							} else { //读数值
								label = '读数值';
							}
							this.wrapperTts(data, {
							      tts: num,
							      label: label,
							      ttsMarkClass: 'tts-mark-number',
							      markType: 'number',
							    });
							this.InitialValue();
							this.$refs.edit.dispatchEvent(new Event('input'));
						} else {
							message.error('选中文本必须包含数字');
							return;
						}
					},

				},
				/*断句*/
				punctuation: {
					BrokenSentences: () => {
						var data = this.editor.CursorEndPosition;
						if(data.collapsed && data.editorSelector ==null){
							message.error('请点击使光标定位到插入位置');
							return;
						}
						var continuityMark = this.createTTSMarkNode({
							tts:"[punctuation]",
							label:"断句",
							className:"tts-mark-segmentation",
							markType:"dj"
						});
						this.createDocumentFragment(continuityMark);
					},
				},
				/*连续*/
				continuity: {
					Continuity: () => {
						var data = this.editor.CursorEndPosition;
						if(data.collapsed && data.editorSelector ==null){
							message.error('请点击使光标定位到插入位置');
							return;
						}
						var continuityMark = this.createTTSMarkNode({
							  tts: `[continuity]`,
							  label: `连读`,
							  className: 'tts-mark-continuity',
							  markType: 'lx',
						});
						this.createDocumentFragment(continuityMark);
					},
				},
				/*停顿*/
				pause: {
					Pause: (num) => {
						var data = this.editor.CursorEndPosition;
						if(data.collapsed && data.editorSelector ==null){
							message.error('请点击使光标定位到插入位置');
							return;
						}
						var continuityMark = this.createTTSMarkNode({
							tts: `[`+num+`s]`,
							label: num+`秒`,
							className: 'tts-mark-pause',
							markType: 'td',
						});
						this.createDocumentFragment(continuityMark);
					},
				},
				/*编辑框*/
				editor: {
					no_after:false,
					CursorEndPosition:{
						    startContainer: null,
						    startOffset: 0,
						    endContainer: null,
						    endOffset: 0,
						    collapsed: true,
						    commonAncestorContainer: null,
						    innerText: '',
							editorSelector:null,
						  },
					/*获取编辑框改变内容*/
					hetext: () => {
						var len = document.querySelectorAll('.tts-content-paragraph').length;
						var remain = this.$refs.edit.innerText.replace(/\n/g, '').length;
						var limitNum = this.default_max_lenth; //可输入的最大值
						var re = limitNum - remain;
						this.min_lenth = remain;
						this.max_lenth = re;
						if(re < 0){
							this.ts_msg='字数超过<span style="color:#09bd71">'+limitNum+'</span>个限制，请进行适当删减!';
						}else{
							this.ts_msg='已输入 <span style="color:#09bd71">'+this.min_lenth+'</span> 字符，还可以输入 <span style="color:#09bd71">'+this.max_lenth+'</span> 字符';
						}
						var text = document.querySelector('.maintextarea').innerText;
						var strLength =text.replace(/\s+/g,"");
						strLength = strLength.replace(/[\r\n]/g,""); 
						if(strLength.length!=0){
							this.Return_data.state = true;
						}else{
							this.Return_data.state =false;
						}
					},
					/*获取选中内容*/
					getSelectedContent: (e) => {
						this.position = {
							content: window.getSelection().toString(),
							start: window.getSelection().anchorOffset,//光标开始的位置
							end: window.getSelection().focusOffset//光标最后的位置
						};
					},
					handleInputBlur:(e)=> {
						var selection = window.getSelection();
						var range = selection.getRangeAt(0);
						this.editor.CursorEndPosition = {
						    startContainer: range.startContainer,
						    startOffset: range.startOffset,
						    endContainer: range.endContainer,
						    endOffset: range.endOffset,
						    collapsed: range.collapsed,
						    commonAncestorContainer: range.commonAncestorContainer,
						    innerText: range.toString(),
							editorSelector: document.querySelector('.maintextarea'),
						  };
					},

				},
				/*撤销*/
				revoke:{
					ttsUndo:{
						cursor:'no-drop',
						opacity:0.12,
					},
					ttsRedo:{
						cursor:'no-drop',
						opacity:0.12,
					},
					//撤销
					tts_undo:()=>{
						this._emitter.undo();
						this.editor.hetext();
					},
					//重做
					tts_redo:()=>{
						this._emitter.redo();
						this.editor.hetext();
					},
				},
			}
		},
		created() {
			this.max_lenth = this.default_max_lenth;
			this.anchor.getAnchorsType();
			this.bgm.getBgmType();
			if(this.info.id){
				this.edit_loading=true;;
				this.$apiRequest.post("/voice/getHtmlText", {
					id:this.info.id
				}).then(res => {
					this.editor.no_after=true;
					this.value_text=res.data.data.result.html_text;
					this.ts_msg='已输入 <span style="color:#09bd71">'+res.data.data.result.length+'</span> 字符，还可以输入 <span style="color:#09bd71">'+(this.default_max_lenth - res.data.data.result.length)+'</span> 字符';
					this.anchor.AnchorStatus={	 //主播
						key: res.data.data.result.anchor_id,
						label: res.data.data.result.anchor_name,
						avatar: res.data.data.result.anchor_avatar,
					};
					this.bgm.bgm_value=res.data.data.result.bgm_volume?res.data.data.result.bgm_volume:50,
					this.bgm.BgmStatus={ //背景音乐
						key: res.data.data.result.bgm_id?res.data.data.result.bgm_id:0,
						label: res.data.data.result.bgm_title?res.data.data.result.bgm_title:'无背景音乐',
						bgm_url: res.data.data.result.bgm_url?res.data.data.result.bgm_url:'',
					};
					this.volume.VolumeStatus= { //音量
						key: res.data.data.result.volume,
						label:res.data.data.result.volume==70?res.data.data.result.volume+' 默认':res.data.data.result.volume,
					};
					this.speech.SpeechStatus= { //语速
						key: res.data.data.result.speed,
						label:res.data.data.result.speed==0?res.data.data.result.speed+' 默认':res.data.data.result.speed,
					};
					this.pitch.PitchStatus= { //语调
						key: res.data.data.result.pitch_rate,
						label:res.data.data.result.pitch_rate==0?res.data.data.result.pitch_rate+' 默认':res.data.data.result.pitch_rate,
					};
					/*播放器*/
					this.audio.AudioDisplay=true;
					this.audio.info={
						filename:res.data.data.result.filename,
						t_url:baseFunc.getQiniusign(res.data.data.result.qiniu_path),
						realMusicTime:'00:00',
						totalMusicTime:this.audio.formatSeconds(res.data.data.result.duration),
						currentTime:0,
						duration:res.data.data.result.duration,
					};
					this.anchor.copy_AnchorStatus=this.anchor.AnchorStatus;
					if(this.bgm.BgmStatus.bgm_url!=''){
						this.bgm.copy_bgm_value=this.bgm.bgm_value;
					}
					this.bgm.copy_BgmStatus=this.bgm.BgmStatus;
					this.volume.copy_VolumeStatus=this.volume.VolumeStatus;
					this.pitch.copy_PitchStatus=this.pitch.PitchStatus;
					this.speech.copy_SpeechStatus=this.speech.SpeechStatus;
					this.Return_data.data ={
							state:true,
							code:res.data.code,
							message:res.data.message,
							data:res.data.data.end_data
					}
					this.old_text=res.data.data.result.html_text;
					this.edit_loading=false;
				}).catch(err => {
					message.error(err.data.message)
				});
			}
		},
		methods: {
			handleCreate(){
				return {
					data:this.Return_data.data,
					state:this.Return_data.state,
				};
			},
			InitialValue(){
				this.editor.CursorEndPosition={
				    startContainer: null,
				    startOffset: 0,
				    endContainer: null,
				    endOffset: 0,
				    collapsed: true,
				    commonAncestorContainer: null,
				    innerText: '',
					editorSelector:null,
				}
			},
			createDocumentFragment(continuityMark){
				const fragment = document.createDocumentFragment();
				fragment.appendChild(continuityMark);
				let range = document.createRange();
				range.setStart(
				  this.editor.CursorEndPosition.startContainer,
				  this.editor.CursorEndPosition.startOffset
				);
				range.setEnd(
				  this.editor.CursorEndPosition.endContainer,
				  this.editor.CursorEndPosition.endOffset
				);
				range.collapse();
				range.insertNode(continuityMark);
				range.selectNode(continuityMark);
				range.collapse();
				this.restoreRange(this);
				setTimeout(() => {
				    this.$refs.edit.dispatchEvent(new Event('input'));
				})
				this.$refs.edit.focus()
				document.execCommand('selectAll', false, null);
				document.getSelection().collapseToEnd();
			},
			createTTSMarkNode(attrs = {}){
			  const ttsMark = document.createElement('i')
			  ttsMark.classList.add('tts-mark-flag')
			  ttsMark.classList.add('tts-mark-point')
			  ttsMark.setAttribute('contenteditable', false)
			  Object.keys(attrs || {}).forEach((key) => {
			    switch (key) {
			      case 'tts':
			        ttsMark.setAttribute('data-tts', attrs.tts)
			        break
			      case 'label':
			        ttsMark.setAttribute('data-label', attrs.label)
			        break
			      case 'markType':
			        ttsMark.setAttribute('data-type', attrs.markType)
			        break
			      case 'text':
			        if (attrs.text) {
			          ttsMark.innerText = attrs.text
			        }
			        break
			      case 'className':
			        const classNames = attrs.className?.split(' ') || []
			        classNames.forEach((cn) => {
			          if (cn) {
			            ttsMark.classList.add(cn)
			          }
			        })
			        break
			      default:
			        void 0
			    }
			  })
			  return ttsMark
			},
			restoreRange(editor) {
			  if (editor.currentSelection) {
			    let selection = window.getSelection()
			    selection.removeAllRanges()
			    let range = document.createRange()
			    range.setStart(
			      editor.currentSelection.startContainer,
			      editor.currentSelection.startOffset
			    )
			    range.setEnd(
			      editor.currentSelection.endContainer,
			      editor.currentSelection.endOffset
			    )
			    // 向选区中添加一个区域
			    selection.addRange(range)
			    editor.currentSelection = null
			  }				
			  this.$refs.edit.focus();
			},
			wrapperTts(editor, ttsConfig) {
			  const { ttsMarkClass, tts, label, markType } = ttsConfig
			  if (!ttsMarkClass) {
				  message.error('缺失标示的tts标注');
				  return;
			  }
			  let range = document.createRange()
			  range.setStart(
			    editor.startContainer,
			    editor.startOffset
			  )
			  range.setEnd(
			    editor.endContainer,
			    editor.endOffset
			  )
			  const start = range.startContainer
			  const startOffset = range.startOffset
			  const end = range.endContainer
			  let endOffset = range.endOffset
			  const common = range.commonAncestorContainer
			  const rangeBefore = document.createRange()
			  rangeBefore.setEnd(start, startOffset)
			  rangeBefore.setStart(editor.editorSelector, 0)
			  const rangeBeforeWrapper = document.createElement('div')
			  rangeBeforeWrapper.appendChild(rangeBefore.cloneContents())
			  const bfSpeakers = rangeBeforeWrapper.querySelectorAll(`.${ttsMarkClass}`)
			  let markStartSpeaker = null
			  if (bfSpeakers.length) {
			    markStartSpeaker = bfSpeakers[bfSpeakers.length - 1]
			    // 最后一个节点是新区间开始, 并不是上一区间结尾，
			    if (markStartSpeaker.classList.contains('mark-range-start')) {
			      markStartSpeaker = {
			        tts: markStartSpeaker.dataset.tts,
			        label: markStartSpeaker.dataset.label,
			      }
			    } else {
			      // 和上一个同类标注没有交集
			      markStartSpeaker = null
			    }
			  }
			  const rangeWrapper = document.createElement('div')
			  rangeWrapper.appendChild(range.cloneContents())
			  const efSpeakers = rangeWrapper.querySelectorAll(`.${ttsMarkClass}`)
			  let markInnerSpeaker = null
			  if (efSpeakers.length) {
			    markInnerSpeaker = efSpeakers[efSpeakers.length - 1]
			    markInnerSpeaker = {
			      tts: markInnerSpeaker.dataset.tts,
			      label: markInnerSpeaker.dataset.label,
			      boundary: markInnerSpeaker.classList.contains('mark-range-start')
			        ? 'start'
			        : 'end',
			    }
			  }
			  /**
			   * 优先处理后置节点， 再处理中间节点，最后前置节点，倒序处理，防止dom变更导致 原有选区位置被破坏，无法定位需要处理的地方
			   * 防止选区位置被破坏
			   */
			  let checkFormatStartNode = null
			  {
			    range.setEnd(end, endOffset)
			    range.collapse()
			    if (!markInnerSpeaker) {
			      // 选区中 无同类标注节点
			      // 会出现两大种情况 1. 选区被已有同类标注区间包围 2.选区为游离选区，与同类标注不存在交集
			      if (markStartSpeaker) {
			        // 1. 选区被已有同类标注区间包围
			        if (markStartSpeaker.tts === tts) {
			          // 当前标注 与原有同类标注 为相同信息
			          // 不做任何处理即可
			        } else {
			          // 原有标注与当前同类标注为不同信息
			          // 1. 需要插入 新标注的 结尾节点
			          // 2. 以及 打断原有标注区间，重新作为后续片段起始节点（原有标注信息）
			          const ttsEndMark = this.createTTSMarkRangeNode({
			            className: `${ttsMarkClass} mark-range-end`,
			            tts: `${tts}`,
			            label: `${label || tts}`,
			            type: 'end',
			            markType: markType,
			          })
			          let fragment = document.createDocumentFragment()
			          fragment.appendChild(ttsEndMark)
			          range.insertNode(fragment)
			          range.collapse()
			          const originTtsStartMark = this.createTTSMarkRangeNode({
			            className: `${ttsMarkClass} mark-range-start`,
			            tts: `${markStartSpeaker.tts}`,
			            label: `${markStartSpeaker.label || markStartSpeaker.tts}`,
			            type: 'start',
			            markType: markType,
			          })
			          fragment = document.createDocumentFragment()
			          fragment.appendChild(originTtsStartMark)
			          range.insertNode(fragment)
			          range.collapse()
			          checkFormatStartNode = originTtsStartMark
			        }
			      } else {
			        // 2.选区为游离选区，与同类标注不存在交集 , 比如 直接对一段没有标注的元素 进行标注等情况
			        // 直接插入 当前标注的截止节点即可
			        const ttsEndMark = this.createTTSMarkRangeNode({
			          className: `${ttsMarkClass} mark-range-end`,
			          tts: `${tts}`,
			          label: `${label || tts}`,
			          type: 'end',
			          markType: markType,
			        })
			        let fragment = document.createDocumentFragment()
			        fragment.appendChild(ttsEndMark)
			        range.insertNode(fragment)
			        range.collapse()
			      }
			    } else {
			      // 选区中 存在同类标注
			      if (markInnerSpeaker.boundary === 'end') {
			        // 选区内 原有标注 已经闭合， 则直接插入 当前标注的截止节点即可
			        // 比如 选区 包含原有标注区间，或者选区右侧 大于等于 原有标注区间右端点
			        const ttsEndMark = this.createTTSMarkRangeNode({
			          className: `${ttsMarkClass} mark-range-end`,
			          tts: `${tts}`,
			          label: `${label || tts}`,
			          type: 'end',
			          markType: markType,
			        })
			        let fragment = document.createDocumentFragment()
			        fragment.appendChild(ttsEndMark)
			        range.insertNode(fragment)
			        range.collapse()
			      } else {
			        // 选区中 存在 原有标注的起始端点， 即 当前选区 < 原有同类标注, 即选区右节点 处于原有标注范围内
			        if (markInnerSpeaker.tts === tts) {
			          // 标注信息 与 原有标注信息相同，原有标注区间 已经包含当前选区右侧节点
			          // 可以不做处理
			        } else {
			          // 原有标注区间信息 与 当前标注信息 不同， 需要对原有进行截断处理， 选区之前采用新的标注，选区之后继承原有标注
			          const ttsEndMark = this.createTTSMarkRangeNode({
			            className: `${ttsMarkClass} mark-range-end`,
			            tts: `${tts}`,
			            label: `${label || tts}`,
			            type: 'end',
			            markType: markType,
			          })
			          let fragment = document.createDocumentFragment()
			          fragment.appendChild(ttsEndMark)
			          range.insertNode(fragment)
			          range.collapse()
			          const originTtsStartMark = this.createTTSMarkRangeNode({
			            className: `${ttsMarkClass} mark-range-start`,
			            tts: `${markInnerSpeaker.tts}`,
			            label: `${markInnerSpeaker.label || markInnerSpeaker.tts}`,
			            type: 'start',
			            markType: markType,
			          })
			          // fragment = document.createDocumentFragment()
			          // fragment.appendChild(originTtsStartMark)
			          range.insertNode(originTtsStartMark)
			          range.collapse()
			          checkFormatStartNode = originTtsStartMark
			        }
			      }
			    }
			  }
			
			  /**
			   * 处理中间选区
			   */
			  {
			    this.removeAllTTSTag(
			      { start, startOffset, end, endOffset, common },
			      `.${ttsMarkClass}`,
			      editor.editorSelector
			    )
			  }
			  /**
			   * 最后处理前置节点
			   */
			  let checkFormatEndNode = null
			  {
			    range.setEnd(start, startOffset)
			    range.collapse()
			    if (!markStartSpeaker) {
			      // 当前选区左侧无同类原有标注起点
			      // 可以直接插入本地标注起点
			      const ttsStartMark = this.createTTSMarkRangeNode({
			        className: `${ttsMarkClass} mark-range-start`,
			        tts: `${tts}`,
			        label: `${label || tts}`,
			        type: 'start',
			        markType: markType,
			      })
			      const fragment1 = document.createDocumentFragment()
			      fragment1.appendChild(ttsStartMark)
			      range.insertNode(fragment1)
			      range.collapse()
			    } else {
			      // 当前选区左侧存在未闭合的同类标注开区间起始点
			      // 即，当前选区起点 在原有同类标注 内部
			      if (markStartSpeaker.tts === tts) {
			        // 当前选区标注信息 与原有标注信息相同
			        // 不需要单独插入起始起点（沿用之前标注信息）
			      } else {
			        // 当前标注信息 与原有范围标注信息不同
			        // 需要截断处理，插入原有标注闭区间节点，然后再插入新标注起点
			        const ttsEndMark = this.createTTSMarkRangeNode({
			          className: `${ttsMarkClass} mark-range-end`,
			          tts: `${markStartSpeaker.tts}`,
			          label: `${markStartSpeaker.label || markStartSpeaker.tts}`,
			          type: 'end',
			          markType: markType,
			        })
			        range.insertNode(ttsEndMark)
			        range.collapse()
			
			        checkFormatEndNode = ttsEndMark
			        const ttsStartMark = this.createTTSMarkRangeNode({
			          className: `${ttsMarkClass} mark-range-start`,
			          tts: `${tts}`,
			          label: `${label || tts}`,
			          type: 'start',
			          markType: markType,
			        })
			        range.insertNode(ttsStartMark)
			        range.collapse()
			      }
			    }
			    // 清空选区
			    const selection = window.getSelection()
			    selection.removeAllRanges()
			    editor.currentSelection = null
			  }
			  /**格式化后节点为空的场景 */
			  if (checkFormatStartNode) {
			    let nAvailNode = this.getVailableSiblings(checkFormatStartNode, 'nextSibling')
			    if (
			      nAvailNode !== null &&
			      nAvailNode.classList?.contains(`mark-range-end`) &&
			      nAvailNode.classList?.contains(`${ttsMarkClass}`)
			    ) {
			      checkFormatStartNode.parentNode.removeChild(nAvailNode)
			      // 要先remove nAvailNode，否则 checkFormatStartNode.parentNode ==null
			      checkFormatStartNode.parentNode.removeChild(checkFormatStartNode)
			      checkFormatStartNode = null
			    }
			  }
			  /**格式化前节点为空的场景 */
			  if (checkFormatEndNode) {
			    let pAvailNode = this.getVailableSiblings(checkFormatEndNode, 'previousSibling')
			    if (
			      pAvailNode !== null &&
			      pAvailNode.classList?.contains(`mark-range-start`) &&
			      pAvailNode.classList?.contains(`${ttsMarkClass}`)
			    ) {
			      checkFormatEndNode.parentNode.removeChild(pAvailNode)
			      // 要先remove p，否则 checkFormatEndNode.parentNode ==null
			      checkFormatEndNode.parentNode.removeChild(checkFormatEndNode)
			      checkFormatEndNode = null
			    }
			  }
			  setTimeout(() => {
			    this.formatTtsRange(editor.editorSelector, [
			      '.tts-mark-vcn',
			      '.tts-mark-number',
			      '.tts-mark-speed',
			      '.tts-mark-intonation',
			    ])
			    editor.editorSelector.dispatchEvent(new Event('input'))
			  })
			},
			createTTSMarkRangeNode(attrs = {}) {
			  const ttsMark = document.createElement('i')
			  ttsMark.classList.add('tts-mark-flag')
			  ttsMark.classList.add('tts-mark-range')
			  ttsMark.setAttribute('contenteditable', false)
			  Object.keys(attrs || {}).forEach((key) => {
			    switch (key) {
			      case 'tts':
			        ttsMark.setAttribute('data-tts', attrs.tts)
			        break
			      case 'label':
			        ttsMark.setAttribute('data-label', attrs.label)
			        break
			      case 'className':
			        const classNames = attrs.className?.split(' ') || []
			        classNames.forEach((cn) => {
			          if (cn) {
			            ttsMark.classList.add(cn)
			          }
			        })
			        break
			      case 'markType':
			        ttsMark.setAttribute('data-type', attrs.markType)
			        break
			      default:
			        void 0
			    }
			  })
			  if (attrs.type === 'start') {
			    const closeTag = document.createElement('i')
			    closeTag.classList.add('tts-range-remove')
			    closeTag.setAttribute('contenteditable', false)
			    closeTag.style.userSelect = 'none'
			    ttsMark.prepend(closeTag)
			    ttsMark.classList.add('mark-range-start')
			  } else {
			    ttsMark.classList.add('mark-range-end')
			  }
			  return ttsMark
			},
			removeAllTTSTag(
			  { start, startOffset, end, endOffset, common },
			  querySelectorFilter,
			  rootNode
			) {
			  const startPath = this.queryPath(start, rootNode, startOffset)
			
			  const endPath = this.queryPath(end, rootNode, endOffset)
			  const allttsTag = rootNode.querySelectorAll(querySelectorFilter)
			  if (!allttsTag.length) {
			    return
			  }
			  let i = allttsTag.length - 1
			  const removes = []
			  while (i >= 0) {
			    const path = this.queryPath(allttsTag[i], rootNode)
			    let contains = this.containsPathBoundary(path, startPath, endPath)
			    if (contains) {
			      removes.push(allttsTag[i])
			    }
			    i--
			  }
			  removes.forEach((n) => {
			    n.parentNode.removeChild(n)
			  })
			},
			 queryPath (node, rootNode, startOffset){
			  var path = []
			  if (node === rootNode || !node) {
				return path
			  }
			  if (startOffset !== undefined && node.nodeType === Node.ELEMENT_NODE) {
				// 光标所在文本节点
				node = node.childNodes[startOffset]
				return this.queryPath(node, rootNode)
			  }
			  if (node.parentNode) {
				path.push(
				  Array.prototype.slice.call(node.parentNode.childNodes, 0).indexOf(node)
				)
				path = this.queryPath(node.parentNode, rootNode).concat(path)
			  }
			  return path
			},
			containsPathBoundary(targetPath = [], startPath = [], endPath = []) {
			  targetPath = [...targetPath]
			  startPath = [...startPath]
			  endPath = [...endPath]
			  let containsStart = false
			  let containsEnd = false
			  // 比较起始边界
			  {
			    let maxLayers = Math.max(targetPath.length, startPath.length)
			    if (maxLayers > targetPath.length) {
			      let i = targetPath.length
			      while (i < maxLayers) {
			        targetPath.push(0)
			        i++
			      }
			    }
			    if (maxLayers > startPath.length) {
			      let i = startPath.length
			      while (i < maxLayers) {
			        startPath.push(0)
			        i++
			      }
			    }
			    let i = 0
			    containsStart = true
			    while (i < maxLayers) {
			      if (targetPath[i] < startPath[i]) {
			        containsStart = false
			        break
			      }
			      i++
			    }
			  }
			  if (!containsStart) {
			    return false
			  }
			  // 比较结束边界
			  {
			    let maxLayers = Math.max(targetPath.length, endPath.length)
			    if (maxLayers > targetPath.length) {
			      let i = targetPath.length
			      while (i < maxLayers) {
			        targetPath.push(0)
			        i++
			      }
			    }
			    if (maxLayers > endPath.length) {
			      let i = endPath.length
			      while (i < maxLayers) {
			        endPath.push(0)
			        i++
			      }
			    }
			
			    containsEnd = true
			    let i = 0
			    while (i < maxLayers) {
			      if (targetPath[i] > endPath[i]) {
			        containsEnd = false
			        break
			      }
			      i++
			    }
			  }
			  return containsStart && containsEnd
			},
			getVailableSiblings(node, type = 'previousSibling') {
			  if (type === 'previousSibling') {
			    if (!node.previousSibling) {
			      return null
			    }
			    let pnode = node.previousSibling
			    while (pnode && pnode.nodeType === Node.TEXT_NODE && !pnode.nodeValue) {
			      pnode = pnode.previousSibling
			    }
			    return pnode
			  } else {
			    if (!node.nextSibling) {
			      return null
			    }
			    let nnode = node.nextSibling
			    while (nnode && nnode.nodeType === Node.TEXT_NODE && !nnode.nodeValue) {
			      nnode = nnode.nextSibling
			    }
			    return nnode
			  }
			},
			formatTtsRange(editorWrapper, ttsTypeSelectors = []) {
			  let curIsRangeStart = false // true or false
			  let lastNodeIsRangeStart = false // true or false
			  let removeNodes = []
			  let rangeMarks = []
			  if(editorWrapper){
				  ttsTypeSelectors.forEach((selector) => {
				    // 重置默认，
				    curIsRangeStart = false
				    // 重置默认，
				    lastNodeIsRangeStart = false
				    removeNodes = []
				    rangeMarks = editorWrapper.querySelectorAll(selector)
				    let startRangeMarker = editorWrapper.querySelectorAll(
				      selector + '.mark-range-start'
				    ).length
				    let endRangeMarker = editorWrapper.querySelectorAll(
				      selector + '.mark-range-end'
				    ).length
				    if (startRangeMarker !== endRangeMarker) {
				      // 出现次数奇数， 由于开始 和结束标签成对出现，因此 其中可能某一项被删除， 需要找到对应的另一端节点 进行删除
				      // 考虑到 节点不会出现交叉， 一定是（起始-结束 起始-结束）  而不会出现 起始-起始-结束-结束的情况
				      rangeMarks.forEach((n, i) => {
				        // 记录上一次类型
				        if (n.classList?.contains('tts-mark-range')) {
				          curIsRangeStart = n.classList?.contains('mark-range-start')
				          if (curIsRangeStart === lastNodeIsRangeStart) {
				            if (!lastNodeIsRangeStart) {
				              // 上一个已经是终止节点，当前也为终止节点，可能是从中间删除， 把 后当前区间的 前置节点删除了
				              // 需要清除当前节点
				              removeNodes.push(n)
				            } else {
				              // 上一个已经是起始节点，当前也为起始节点，可能是后面把上一个选区的后置节点给删除了
				              // 需要清除上一个节点（没有手动删除的前置的节点）
				              removeNodes.push(rangeMarks[i - 1])
				            }
				          }
				          lastNodeIsRangeStart = curIsRangeStart
				        }
				      })
				      if (lastNodeIsRangeStart) {
				        // 说明最后一个节点是 起始节点，予以删除
				        removeNodes.push(rangeMarks[rangeMarks.length - 1])
				      }
				    }
				    if (removeNodes.length) {
				      removeNodes.forEach((n) => {
				        try {
				          n.parentNode.removeChild(n)
				        } catch (e) {}
				      })
				    }
				    removeNodes = []
				    rangeMarks = []
				  })
			  }
			},
			fixBackspace(editor) {
			  let selection = window.getSelection()
			  if (selection.rangeCount && this.editorFocused(editor)) {
			    const range = selection.getRangeAt(0)
			    if (range.collapsed) {
			      let preNode = null
			      if (range.commonAncestorContainer.nodeType === Node.ELEMENT_NODE) {
			        preNode =
			          range.commonAncestorContainer.childNodes[range.startOffset]
			            ?.previousSibling
			        if (!preNode?.classList?.contains('tts-mark-flag')) {
			          preNode = null
			        }
			        if (preNode) {
			          range.setStartBefore(preNode)
			        }
			      } else if (
			        range.commonAncestorContainer.nodeType === Node.TEXT_NODE &&
			        range.startOffset === 0
			      ) {
			        preNode = range.commonAncestorContainer.previousSibling
			        if (preNode?.nodeType === Node.TEXT_NODE && !preNode.nodeValue) {
			          // 前面有空文本节点
			          // 跳过继续往前
			          preNode = range.commonAncestorContainer.previousSibling
			        }
			        if (preNode?.classList?.contains('tts-mark-flag')) {
			          range.setStartBefore(preNode)
			        } else if (!preNode) {
			          preNode = range.commonAncestorContainer.parentNode
			          if (
			            preNode.previousSibling &&
			            !preNode.previousSibling.classList?.contains(
			              'tts-content-paragraph'
			            )
			          ) {
			            range.selectNode(preNode.previousSibling)
			          } else {
			            range.setStartBefore(preNode)
			            range.setEndAfter(preNode)
			            range.collapse(true)
			          }
			        }
			      }
			    }
			  }
			  setTimeout(() => {
			    this.formatTtsRange(editor.editorSelector, [
			      '.tts-mark-vcn',
			      '.tts-mark-number',
			      '.tts-mark-speed',
			      '.tts-mark-intonation',
				  '.tts-mark-volume',
			    ])
			  })
			},
			 editorFocused(editor) {
			  const selection = window.getSelection()
			  if (!selection.rangeCount) {
			    return false
			  }
			  const range = selection.getRangeAt(0)
			  const sourceRange = document.createRange()
			  sourceRange.selectNode(document.querySelector('.maintextarea'))
			  const compareStart = range.compareBoundaryPoints(
			    Range.START_TO_START,
			    sourceRange
			  )
			  const compareEnd = range.compareBoundaryPoints(Range.END_TO_END, sourceRange)
			  if (compareStart >= 0 && compareEnd <= 0) {
			    return true
			  }
			  return false
			},
			 editorLimitCheck() {
			    const len = document.querySelectorAll('.tts-content-paragraph').length;
			    const words = Math.max(this.calculateWords(this.$refs.edit.innerText.replace(/\n/g, '')) + len - 1,0)
			    if (words >= this.default_max_lenth) {
			      return {
			        words: words,
			        limit: true,
			        diff: this.default_max_lenth - words,
			      }
			    }
			    return {
			      words: words,
			      limit: false,
			      diff: this.default_max_lenth - words,
			    }
			  },
			  calculateWords(str) {
			    if (str.length === 1) {
			      return str.replace(/\n/, '').length
			    }
			    return str.length
			  },
			  insertBreak(editor) {
			    let selection = window.getSelection()
			    if (selection.rangeCount && this.editorFocused(editor)) {
			      let oldRange = selection.getRangeAt(0)
			      let paragraphNode = this.findParentsNode(
			        oldRange.endContainer,
			        editor.editorSelector,
			        (node) => {
			          if (
			            node.nodeType === Node.ELEMENT_NODE &&
			            node.classList?.contains('tts-content-paragraph')
			          ) {
			            return true
			          }
			          return false
			        }
			      )
			      let documentFragment
			      if (paragraphNode) {
			        const breakRange = document.createRange()
			        breakRange.setStart(oldRange.endContainer, oldRange.endOffset)
			        const lastNode =
			          paragraphNode.childNodes[paragraphNode.childNodes.length - 1]
			        let bEnd =
			          lastNode.nodeType === Node.TEXT_NODE
			            ? lastNode.nodeValue.length
			            : lastNode.childNodes.length
			        breakRange.setEnd(
			          paragraphNode.childNodes[paragraphNode.childNodes.length - 1],
			          bEnd
			        )
			        documentFragment = breakRange.extractContents()
			      } else {
			        documentFragment = document.createDocumentFragment()
			      }
			      const breakParagraphNode = this.parseParagraphContent2Paragraph(documentFragment)
			  
			      let pInsertCaret = document.createRange()
			      if (paragraphNode) {
			        pInsertCaret.selectNode(paragraphNode)
			        pInsertCaret.collapse()
			      } else {
			        pInsertCaret.setStart(oldRange.startContainer, oldRange.startOffset)
			        pInsertCaret.setEnd(oldRange.endContainer, oldRange.endOffset)
			        pInsertCaret.collapse()
			      }
			      pInsertCaret.insertNode(breakParagraphNode)
			  
			      if (paragraphNode) {
			        pInsertCaret.setStartBefore(breakParagraphNode)
			      } else {
			        pInsertCaret.setStartAfter(breakParagraphNode)
			      }
			      selection.removeAllRanges()
			      selection.addRange(pInsertCaret)
			      pInsertCaret.collapse(true)
			    }
			  },
			  findParentsNode(startNode, endNode = document.body, filterFn) {
			    if (!endNode) {
			      endNode = document.body
			    }
			    if (filterFn(startNode)) {
			      return startNode
			    }
			    if (startNode === endNode) {
			      return null
			    }
			    return this.findParentsNode(startNode.parentNode, endNode, filterFn)
			  },
			  parseParagraphContent2Paragraph(fragment) {
			    const paragraphNode = document.createElement('div')
			    paragraphNode.classList.add('tts-content-paragraph')
			  
			    fragment.childNodes.forEach((n) => {
			      if (n.nodeType === Node.TEXT_NODE && n.nodeValue) {
			        paragraphNode.appendChild(n.cloneNode(true))
			      } else if (
			        n.nodeType === Node.ELEMENT_NODE &&
			        n.classList?.contains('tts-mark-flag')
			      ) {
			        paragraphNode.appendChild(n.cloneNode(true))
			      } else {
			        // 非标注节点， 一律取文本格式
			        if (n.innerText) {
			          paragraphNode.appendChild(document.createTextNode(n.innerText))
			        }
			      }
			    })
			    if (!paragraphNode.childNodes.length) {
			      paragraphNode.appendChild(document.createElement('br'))
			    }
			    return paragraphNode
			  },
			  formatBreak(editor) {
			    const lastChild =
			      editor.childNodes[
			        editor.childNodes.length - 1
			      ]
			    if (!lastChild) {
			      const wrapper = document.createElement('div')
			      wrapper.classList.add('tts-content-paragraph')
			      wrapper.appendChild(document.createElement('br'))
			      editor.appendChild(wrapper)
			    } else if (
			      lastChild.nodeType === Node.ELEMENT_NODE &&
			      lastChild.nodeName.toLowerCase() === 'br'
			    ) {
			      if (lastChild.parentNode === editor) {
			        const wrapper = document.createElement('div')
			        wrapper.classList.add('tts-content-paragraph')
			        wrapper.appendChild(document.createElement('br'))
			        editor.replaceChild(wrapper, lastChild)
			      }
			    } else if (lastChild.nodeType === Node.TEXT_NODE) {
			      const wrapper = document.createElement('div')
			      wrapper.classList.add('tts-content-paragraph')
			      wrapper.appendChild(document.createTextNode(lastChild.nodeValue))
			      editor.replaceChild(wrapper, lastChild)
			    }
			  },
			  formatCaret(editor) {
			    let selection = window.getSelection()
			    if (selection.rangeCount) {
			      let range = selection.getRangeAt(0)
			      const containerNode =
			        range.commonAncestorContainer.nodeType === Node.TEXT_NODE? range.commonAncestorContainer.parentNode: range.commonAncestorContainer
			      if (containerNode.classList?.contains('tts-mark-flag')) {
			        if (range.collapsed) {
			          let i = 0
			          while (i < containerNode.parentNode.childNodes.length) {
			            if (containerNode.parentNode.childNodes[i] === containerNode) {
			              break
			            }
			            i++
			          }
			          range.setStart(containerNode.parentNode, i + 1)
			          range.setEnd(containerNode.parentNode, i + 1)
			          range.collapse()
			        }
			      }
			    }
			  },
			  getClipboardData(pasteEvent){
				   // 初始化操作
				    return new Promise((resolve, reject) => {
				      let pasteText = ''
				      if (pasteEvent?.clipboardData) {
				        pasteText = pasteEvent.clipboardData.getData('text/plain')
				        // 要阻止默认粘贴行为
				        // 去除表情符号
				        pasteText = pasteText.replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g,'')
				        pasteEvent.preventDefault()
				        return resolve(pasteText)
				      }
				      var $pasteInput = document.createElement('input')
				      $pasteInput.style.position = 'fixed'
				      $pasteInput.style.left = '-100px'
				      $pasteInput.style.top = '0'
				      $pasteInput.style.height = '1px'
				      $pasteInput.style.width = '1px'
				      $pasteInput.value = ''
				      document.body.appendChild($pasteInput)
				      setTimeout(() => {
				        // 去除表情符号
				        pasteText = $pasteInput.value.replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g,'')
				        resolve(pasteText)
				        document.body.removeChild($pasteInput)
				      })
				      $pasteInput.focus()
				    })
			  },
			/*语音合成*/
			GenerateVoice() {
				var oldText =document.querySelector('.maintextarea').innerHTML;
				if(this.bgm.BgmStatus.bgm_url!=''){
					if(this.isEqual(this.anchor.AnchorStatus,this.anchor.copy_AnchorStatus)
					&& this.isEqual(this.bgm.bgm_value,this.bgm.copy_bgm_value) 
					&& this.isEqual(this.volume.VolumeStatus,this.volume.copy_VolumeStatus)
					&& this.isEqual(this.pitch.PitchStatus,this.pitch.copy_PitchStatus)
					&& this.isEqual(this.speech.SpeechStatus,this.speech.copy_SpeechStatus)
					&& oldText == this.old_text
					){
						message.error('内容一样无需重复合成!');
						return;
					}
				}else{
					if(this.isEqual(this.anchor.AnchorStatus,this.anchor.copy_AnchorStatus)
					&& this.isEqual(this.bgm.BgmStatus,this.bgm.copy_BgmStatus) 
					&& this.isEqual(this.volume.VolumeStatus,this.volume.copy_VolumeStatus)
					&& this.isEqual(this.pitch.PitchStatus,this.pitch.copy_PitchStatus)
					&& this.isEqual(this.speech.SpeechStatus,this.speech.copy_SpeechStatus)
					&& oldText == this.old_text
					){
						message.error('内容一样无需重复合成!');
						return;
					}
				}
				this.anchor.copy_AnchorStatus=this.anchor.AnchorStatus;
				if(this.bgm.BgmStatus.bgm_url!=''){
					this.bgm.copy_bgm_value=this.bgm.bgm_value;
				}
				this.bgm.copy_BgmStatus=this.bgm.BgmStatus;
				this.volume.copy_VolumeStatus=this.volume.VolumeStatus;
				this.pitch.copy_PitchStatus=this.pitch.PitchStatus;
				this.speech.copy_SpeechStatus=this.speech.SpeechStatus;
				const limit = this.editorLimitCheck()
				if (limit.limit) {
					message.error('超过限制');
					return;
				}
				this.audio.AudioDisplay=false;
				var text = document.querySelector('.maintextarea').innerText;
				var su_text=text;
				var strLength =text.replace(/\s+/g,"");
				strLength = strLength.replace(/[\r\n]/g,""); 
				if(strLength.length==0){
					message.error('请输入合成语音内容!');
					return;
				}
				var i = document.querySelectorAll('.tts-content-paragraph i');
				for(var p=0;p<i.length;p++ ){
					var val = i[p].getAttribute('data-tts');
					var ival = parseInt(val);
					if(!isNaN(ival)){
						var a = '[';
						var b = ']';
						var data = i[p].innerText + (a+i[p].getAttribute('data-type')+ival+b);
					} else{
						var c=i[p].getAttribute('data-tts');
						if(c==null){
							c='';
						}
						var data = i[p].innerText + c;
					}
					i[p].replaceWith(data);
				}
				this.old_text=oldText;
				var new_text = document.querySelector('.maintextarea').innerText;
				this.$refs.edit.innerHTML=oldText;
				this.audio.AudioStatus=true;
				this.$apiRequest.post("/voice/GenerateVoice", {
					length:this.min_lenth,
					html_text:oldText,
					text:new_text,
					voice: this.anchor.AnchorStatus.value?this.anchor.AnchorStatus.value:this.anchor.AnchorStatus.key,
					bgm_id: this.bgm.BgmStatus.key,
					bgm_volume: this.bgm.bgm_value,
					volume: this.volume.VolumeStatus.value?this.volume.VolumeStatus.value:this.volume.VolumeStatus.key,
					pitch_rate: this.pitch.PitchStatus.value?this.pitch.PitchStatus.value:this.pitch.PitchStatus.key,
					speech_rate: this.speech.SpeechStatus.value?this.speech.SpeechStatus.value:this.speech.SpeechStatus.key
				}).then(res => {
					this.audio.AudioStatus=false;
					if(res.data.code==200){
						this.audio.info.filename=res.data.data.filename;
						this.audio.info.duration=res.data.data.duration;
						this.audio.info.t_url=baseFunc.getQiniusign(res.data.data.transcode_url);
						this.audio.info.totalMusicTime=this.audio.formatSeconds(res.data.data.duration);

						this.Return_data={
								state:true,
								code:res.data.code,
								message:res.data.message,
								data:res.data.data
						};
						this.audio.AudioDisplay=true;
					}
				}).catch(err => {
					if(err.data.code==11001 || err.data.code==11000){
						this.$emit("differentiation",{
							code:err.data.code,
							message:err.data.message
						});
					}else{
						message.error(err.data.message);
					}
					this.Return_data={
						state:true,
						code:err.data.code,
						message:err.data.message,
						data:''
					};
					this.audio.AudioStatus=false;
				});
			},
			/*下载语音*/
			DownloadVoice() {
				if(!this.audio.info.t_url) {
				　　	message.error('请先合成，再下载!');
					return;
				}　　
				let fileName = this.audio.info.filename; // 文件名
				axios({
				　　　　method: 'get',
				　　　　url: this.audio.info.t_url,
				　　　　responseType: 'blob',
				　　　　headers: { 'content-type': 'audio/mpeg' },
				　　}).then((res) => {

				　　}).catch(function (error) {
				　　　　	let blobType = 'application/force-download' // 设置blob请求头
				　　　　let blob = new Blob([error.data], { type: error.data.type }) // 创建blob 设置blob文件类型 data 设置为后端返回的文件(例如mp3,jpeg) type:这里设置后端返回的类型 为 mp3
				　　　　let downa = document.createElement('a') // 创建A标签
				　　　　let href = window.URL.createObjectURL(blob) // 创建下载的链接
				　　　　downa.href = href // 下载地址
				　　　　downa.download = fileName // 下载文件名
				　　　　document.body.appendChild(downa)
				　　　　downa.click() // 模拟点击A标签
				　　　　document.body.removeChild(downa) // 下载完成移除元素
				　　　　window.URL.revokeObjectURL(href) // 释放blob对象
				　　})
			},
			isEqual(objA,objB){
						    //相等
						    if(objA === objB) return objA !== 0 || 1/objA === 1/objB;
						    //空判断
						    if(objA == null || objB == null) return objA === objB;
						    //类型判断
						    if(Object.prototype.toString.call(objA) !== Object.prototype.toString.call(objB)) return false;
						
						    switch(Object.prototype.toString.call(objA)){
						        case '[object RegExp]':
						        case '[object String]':
						            //字符串转换比较
						            return '' + objA ==='' + objB;
						        case '[object Number]':
						            //数字转换比较,判断是否为NaN
						            if(+objA !== +objA){
						                return +objB !== +objB;
						            }
						
						            return +objA === 0?1/ +objA === 1/objB : +objA === +objB;
						        case '[object Date]':
						        case '[object Boolean]':
						            return +objA === +objB;
						        case '[object Array]':
						            //判断数组
						            for(let i = 0; i < objA.length; i++){
						                if (!this.isEqual(objA[i],objB[i])) return false;
						            }
						            return true;
						        case '[object Object]':
						            //判断对象
						            let keys = Object.keys(objA);
						            for(let i = 0; i < keys.length; i++){
						                if (!this.isEqual(objA[keys[i]],objB[keys[i]])) return false;
						            }
						
						            keys = Object.keys(objB);
						            for(let i = 0; i < keys.length; i++){
						                if (!this.isEqual(objA[keys[i]],objB[keys[i]])) return false;
						            }
						
						            return true;
						        default :
						            return false;
						    }
						},
		},
		mounted() {			
			let that = this;
			var ttsEditor= new tts_Editor('#maintextarea', {maxWords: that.default_max_lenth});
			ttsEditor.init();
			that._emitter=ttsEditor;
			that.max_lenth = that.default_max_lenth;
			//撤销
			ttsEditor.on('can-undo', function (canUndo){
				if (!canUndo) {
					that.revoke.ttsUndo={
						cursor:'no-drop',
						opacity:0.12,
					};
				} else {
					that.revoke.ttsUndo={
						cursor:'pointer',
						opacity:'unset',
					};
				}
			});
			//重做
			ttsEditor.on('can-redo', function (canRedo){
				if (!canRedo) {
					that.revoke.ttsRedo={
						cursor:'no-drop',
						opacity:0.12,
					};
				} else {
					that.revoke.ttsRedo={
						cursor:'pointer',
						opacity:'unset',
					};
				}
			});
			that.$nextTick(() => {
				that.$refs.music.addEventListener(
					"timeupdate",
					() => {
						this.audio.handlMusicTime();
					},
					false
				);
				//播放
				that.$refs.music.addEventListener("play", () => {
					that.audio.isPlay = false;
				});
				//暂停
				that.$refs.music.addEventListener("pause", () => {
					that.audio.isPlay = true;
				});
				// 播放完毕
				that.$refs.music.addEventListener("ended", () => {
					that.audio.info.currentTime = 0;
					that.audio.info.realMusicTime = "00:00";
					that.audio.isPlay = true;
				});
				that.$refs.music.oncanplaythrough = () => {

				};
				that.$refs.edit.addEventListener('focus', (event) => {
				  that.formatBreak(that.$refs.edit);
				  that.formatCaret(that.$refs.edit);
				})

				that.$refs.edit.addEventListener("click", function(event) {
					if(event.target.classList?.contains('maintextarea')){
						if(that.editor.CursorEndPosition.editorSelector == null || that.editor.CursorEndPosition.editorSelector == ''){
							that.editor.CursorEndPosition.editorSelector=document.querySelector('.maintextarea')
						}
					}
				});
				that.$refs.edit.addEventListener('keydown', (event) => {
				if (
					// 暂不支持ctrl + z 撤销
					(event.ctrlKey || event.metaKey) &&
					event.keyCode == 90
				  ) {
					return event.preventDefault()
				  }
				  // 检查字数是否超过限制
				  const limit = that.editorLimitCheck(event)
				  if (limit.limit) {
					if (
					  // 回退 删除键
					  event.keyCode === 8 ||
					  // Del 按键
					  event.keyCode === 46 ||
					  event.ctrlKey ||
					  event.metaKey ||
					  // ctrl + a 全选
					  ((event.ctrlKey || event.metaKey) && event.keyCode == 65) ||
					  // ctrl + z 撤销
					  ((event.ctrlKey || event.metaKey) && event.keyCode == 90) ||
					  // ctrl + c 复制
					  ((event.ctrlKey || event.metaKey) && event.keyCode == 67)
					) {
					  // 字数超限 这几种操作可以继续
					} else {
					  return event.preventDefault()
					}
				  }

				  // fix: 连续标签单次无法回退删除
				  that.$refs.edit.setAttribute('empty', '')
				  if (event.keyCode === 8) {
					that.fixBackspace(that.editor.CursorEndPosition)
					if (that.$refs.edit.innerText.length == 0) {
						that.$refs.edit.setAttribute('empty', true)
					}
				  } else if (event.keyCode === 13) {
					event.preventDefault()
					that.insertBreak(that.editor.CursorEndPosition)
					that.$refs.edit.dispatchEvent(new Event('input'))
				  }
				});
				that.$refs.edit.addEventListener('keyup', (event) => {
				  if (event.keyCode === 8) {
					that.formatBreak(that.$refs.edit)
					that.$refs.edit.blur()
					that.restoreRange(that.editor.CursorEndPosition)
				  }
				})
		
				that.$refs.edit.addEventListener('paste', (event) => {
				      // 获取clicpboarddata做了兼容性转化处理，期间会出现光标丢失
				      that.getClipboardData(event)
				        .then((plainText) => {
				          // 检查字数是否超过限制
				          const limit = that.editorLimitCheck()
				          if (limit.limit) {
							  message.error('超过限制');
							  return;
				          }
				          if (limit.diff > 0) {
				            if (plainText.length > limit.diff) {
								message.error('粘贴文本超过限制，截取处理');
								return;
				            }
				            plainText = plainText.substr(0, limit.diff)
				          }
				          that.restoreRange(that.editor.CursorEndPosition)
				          document.execCommand('insertText', false, plainText)
				        })
				        .catch((e) => {
							message.error('剪贴板数据获取异常');
							return;
				        })
				      return false
				})
			});
		}
	}
</script>

<style scoped>
	.outoptions-default-dropdown{
		display: flex;
		display: -webkit-flex;
		padding: 4px 11px;
		text-align: left;
	}
	.outoptions-default-dropdown text{
		-webkit-box-flex: 1;
		-ms-flex: 1;
		flex: 1;
		overflow: hidden;
		white-space: nowrap;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		padding-right: 18px;
	}
	.outoptions-default-dropdown span.outoptions-default-dropdown-icon{
		position: absolute;
		right: 11px;
		top: 5px;
	}
	>>>.maintextarea {
		font-size: 14px;
		font-weight: 400;
		color: black;
		line-height: 20px;
	}

	>>>.tts-mark-point {
		display: inline-block;
		align-items: center;
		justify-content: center;
		border-radius: 2px;
		color: white;
		padding: 0 5px;
		cursor: pointer;
		margin: 0 2px;
		line-height: inherit;
		font-style: normal;
	}

	>>>.tts-mark-point.tts-mark-pause {
		background: #7d78ef;
	}

	>>>.tts-mark-point.tts-mark-continuity {
		background: #7ec143;
	}

	>>>.tts-mark-point.tts-mark-segmentation {
		background: #b5c700;
	}

	>>>.tts-mark-point::after {
		content: attr(data-label);
		font-size: 11px;
	}
	>>>:after,
	:before {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	>>>.tts-mark-range.tts-mark-number.mark-range-start{
		background: #f6a64e;
		padding: 0 5px;
	}
	>>>.tts-mark-range.mark-range-start{
		position: relative;
		margin-right: 1em;
	}
	>>>.tts-mark-range {
		display: inline-block;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 2px;
		color: white;
		line-height: inherit;
		user-select: none;
		padding: 0 5px;
		margin: 0 2px;
		
	}
	>>> i{
		font-style: normal;
	}
	>>>.tts-mark-range.mark-range-start::before{
		content: attr(data-label);
		font-size: 11px;
	}
	>>>.tts-mark-range .tts-range-remove{
		cursor: pointer;
		position: relative;
		vertical-align: middle;
		user-select: none;
	}
	>>>.tts-range-remove{
		z-index: 10;
	}
	>>>.tts-range-remove::after{
		content: '';
		display: inline-block;
		width: 11px;
		height: 11px;
		background: transparent url(/img/cha.png) center no-repeat;
		background-size: contain;
		color: white;
		transform: scale(1.2);
		box-sizing: border-box;
		margin-left: 5px;
	}
	>>>.tts-mark-range.tts-mark-number.mark-range-start::after{
		color: #f6a64e;
	}
	>>>.tts-mark-range.mark-range-start::after{
		content: '「';
		position: absolute;
		right: -.8em;
		top: 0;
		font-weight: 700;
	}
	>>>.tts-mark-range.mark-range-end{
		padding: 0;
		margin: 0;
		margin-left: 3px;
	}
	>>>.tts-mark-range.tts-mark-number.mark-range-end::after{
		color: #f6a64e;
	}
	>>>.tts-mark-range.mark-range-end::after{
		content: '」';
		font-weight: 700;
	}
	
	>>>.tts-mark-range.tts-mark-volume.mark-range-start{
		background: #18B671;
		padding: 0 5px;
	}
	>>>.tts-mark-range.tts-mark-volume.mark-range-start::after{
		color: #18B671;
	}
	>>>.tts-mark-range.tts-mark-volume.mark-range-end::after{
		color: #18B671;
	}
	>>>.tts-mark-range.tts-mark-intonation.mark-range-start{
		background: #00A1FF;
		padding: 0 5px;
	}
	>>>.tts-mark-range.tts-mark-intonation.mark-range-start::after{
		color: #00A1FF;
	}
	>>>.tts-mark-range.tts-mark-intonation.mark-range-end::after{
		color: #00A1FF;
	}
	>>>.tts-mark-range.tts-mark-speed.mark-range-start{
		background: #E96C5E;
		padding: 0 5px;
	}
	>>>.tts-mark-range.tts-mark-speed.mark-range-start::after{
		color: #E96C5E;
	}
	>>>.tts-mark-range.tts-mark-speed.mark-range-end::after{
		color: #E96C5E;
	}
	>>>.no-after:after{
		content:"" !important;
		}
	>>>.maintextarea[empty='true']::after{
		content: "请输入配音内容...";
		font-size: 14px;
		font-weight: 400;
		color: #acacac;
		line-height: 20px;
		position: absolute;
		top: 0;
		transform: translateY(100%);
		-webkit-transform: translateY(100%);
		-moz-transform: translateY(100%);
		-o-transform: translateY(100%);
	}

	>>>.tts-content-paragraph {
	    font-size: 14px;
	    font-weight: 400;
	    color: black;
	    line-height: 20px;
	}
	>>>.tts-mark-point.tts-mark-annotation{
		color: inherit;
		margin: 0 0;
		padding: 0 0;
		user-select: auto;
	}
	>>>.tts-mark-point.tts-mark-annotation::after{
		color: #fe770f;
		font-weight: 600;
	}
	>>>.pinyin-items-wrapper{
		z-index: 100;
		color: #fff;
		font-size: 14px;
		overflow: hidden;
		position: fixed;
		-webkit-box-shadow: 1px 1px 3px rgba(0,0,0,.2);
		box-shadow: 1px 1px 3px rgba(0,0,0,.2);
		padding: 8px 15px;
		border-radius: 5px;
		background-color: #ffffff;
	}
	>>>.pinyin-item-ttsmark{
		float: left;
		margin-right: 2px;
		padding: 0 6px;
		cursor: pointer;
		border-radius: 6px;
		background: #ff181b;
	}
	>>>.pinyin-item-cancel{
		float: left;
		padding: 0 6px;
		cursor: pointer;
		border-radius: 6px;
		background: #ff181b;
	}
	>>>.sanjiaoxing{
		position: fixed;
		width: 0;
		height: 0;
		border-width: 8px;
		border-color: transparent;
		border-style: dashed;
		z-index: 100;
		border-right-style: solid;
		border-right-color: rgb(255, 255, 255);
		margin-top: -16px;
		margin-left: -20px;
	}
	.py-dialog-content{
		height: 280px;
		word-break: break-all;
		padding: 0 15px;
		margin: 10px 0 0;
		overflow-y: auto;
	}
	>>>.py_mask{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		background-color: rgb(0, 0, 0);
		opacity: 0.3;
	}
	>>>.DivPostion{
		position: fixed;
		z-index: 19991033;
		background-color: rgb(255, 255, 255);
		padding: 5px 10px;
		border-radius: 2px;
		box-shadow: 1px 1px 3px rgba(0,0,0,.2);
		font-size: 12px;
		line-height: 22px;
		margin-top: 10px;
	}
	>>>.triangle{
		border-style: solid;
		border-width: 0px 10px 10px 10px;
		border-color: transparent transparent #ffffff transparent;
		width: 0px;
		height: 0px;
		position: absolute;
		top: -5px;
		right: 0;
		left: -4px;
		bottom: 0;
		transform: rotate(-90deg);
	}
	.buttoncontainer {
		display: flex;
		justify-content: center;
		margin-top: 20px;
	}

	.price-tips {
		cursor: pointer;
		margin-left: 5px;
	}

	.textlength-tips span {
		color: red;
	}

	.textlength-tips {
		font-size: 14px;
		color: #5e5e5e;
		margin: auto;
		margin-right: 0;
	}

	.maintextarea:focus {
		box-shadow: 0 0 8px rgba(9, 189, 113, .6);
	}

	.maintextarea {
		height: 300px;
		background-color: #ffffff;
		border-radius: 4px;
		word-break: break-all;
		padding: 20px;
		outline: none;
		caret-color: #09bd71;
		word-break: break-all;
	}

	.main-options-back {
		margin-left: 120px;
	}

	.main-options-reset p,
	.main-options-back p {
		margin-top: 7px;
	}

	.main-options-item-bgimgR {
		width: 24px;
		height: 24px;
		background-image: url(/img/texttospeech/right.png);
		background-size: 100% 100%;
		margin: 0 auto;
		opacity: 0.12;

	}

	.main-options-item-bgimgL {
		width: 24px;
		height: 24px;
		background-image: url(/img/texttospeech/left.png);
		background-size: 100% 100%;
		margin: 0 auto;
		opacity: 0.12;
	}
	
	.main-options-back,
	.main-options-reset {
		width: 48px;
		height: 48px;
		font-size: 12px;
		text-align: center;
		cursor: pointer;
	}

	.main-options-back:hover p {
		color: #09bd71;
	}

	.main-options-reset:hover p {
		color: #09bd71;
	}

	.main-options-item {
		text-align: center;
		cursor: pointer;
		margin: auto;
	}

	.main-options-item:hover p {
		color: #09bd71;
	}

	.main-options-item p {
		text-align: center;
		font-size: 12px;
		margin-top: 4px;
	}


	.main-options {
		height: 80px;
		display: flex;
		align-items: center;
	}

	.mainContainer {
		padding: 0 40px;
		background-color: #eeeeee;
		margin-top: 25px;
	}

	.mainitem {
		height: auto;
	}
	.audioStyle{
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 60px;
	}
	.outoptionsItem-name {
		font-size: 14px;
		color: #333333;
		margin-right:5px;
	}

	.outoptionsItem {
		display: flex;
		display: -webkit-flex;
		align-items: center;
	}
	.outoptionsItem .outoptionsItemSelect{position: relative;}
	.select_hczb {
		/* position: absolute;
		z-index: 999;
		top: 20px;
		right: 0;
		left: 0;
		bottom: 0; */
		height: 380px;
		/* padding-top: 10px; */
		width: 530px;
	}


	.outoptionsItem img {
		width: 30px;
		height: 30px;
		border-radius: 50%;
		object-fit: contain;
		margin-right: 6px;
	}

	.outOptions {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.container {
		width: 100%;
		margin: 0 auto;
	}

	* {
		margin: 0;
		padding: 0;
		box-sizing: border-box;
	}


	.hczbTab {
		margin: 0px;
		border: 0px solid #d8d8d8;
		border-radius: 4px;
		background-color: #ffffff;
		box-shadow: 1px 1px 5px #d8d8d8;
	}

	.layui-tab {
		/* margin: 10px 0; */
		text-align: left !important;
	}


	.hczbTabItem li {
		width: 100px;
		height: 32px;
		line-height: 28px;
		margin: 0 12px 5px 12px;
		list-style: none;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		overflow: hidden;
		display: inline-block;
		padding: 0 10px 0 16px;
		border: 1px solid #ececec00;
	}

	.hczbTabItem li img {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	.nuwccardimg {
		width: 100%;
		height: 100%;
		border-radius: 100%;
	}

	.hczbTabItem li:hover {
		cursor: pointer;
		border: 1px solid #ececec;
		border-radius: 10px;
		box-shadow: 1px 2px 10px 0 #ececec;
		height: 32px;
	}

	.select_bgm {
		position: absolute;
		z-index: 999;
		top: 0;
		bottom: 0;
		right: 0;
		width: 520px;
		height: 420px;
	}

	.select_bgm>div {
		height: 400px;
	}

	.bgmTabItem {
		height: 300px;
		overflow: auto;
	}

	.bgmTabItem li {
		width: 170px;
		height: 30px;
		line-height: 30px;
		padding: 0 20px;
		list-style: none;
		display: inline-flex;
		cursor: pointer;
		border: 1px solid #d8d8d800;
	}
	.bgmTabItem li:hover{
		cursor: pointer;
		border: 1px solid #d8d8d8;
		border-radius: 10px;
		box-shadow: 1px 2px 10px 0 #d8d8d8;
	}
	.bgmTabItem li span {
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		word-break: keep-all;
		width: 128px;
		top: -28px;
		left: 24px;
		line-height: 24px;
	}

	.example {
		text-align: center;
		background: rgba(0, 0, 0, 0.05);
		border-radius: 4px;
		position: absolute;
		z-index: 20;
	}

	.an_type {
		width: 530px;
		height: 45px;
	}

	.an_list {
		width: 530px;
		height: 300px;
	}

	.bgm_type {
		width: 520px;
		height: 45px;
	}

	.bgm_list {
		width: 518px;
		height: 296px;
		margin-top: -16px;
	}

	.number_block {
		background: white;
		box-shadow: 0px 3px 8px 0px rgb(0 0 0 / 20%);
		border-radius: 4px;
		border: 1px solid #09bd71;
		width: 80px;
		position: absolute;
		left: -25px;
		top: 25px;
		display: none;
		z-index: 30;
	}

	.read_number {
		position: relative;
	}

	.read_number:hover .number_block {
		display: block;
	}

	.tts-item {
		display: flex;
		height: 22px;
		font-size: 11px;
		font-weight: 400;
		color: rgba(68, 68, 68, 1);
		line-height: 22px;
		align-items: center;
		justify-content: center;
	}

	.tts-item:hover {
		color: #09bd71;
	}

	.pasue_number {
		position: relative;
	}

	.pasue_number:hover .tts-pause-items {
		display: block;
	}

	.local_pitch {
		position: relative;
	}

	.local_pitch:hover .pitch_slider {
		display: flex;
	}

	.currency_slider {
		height: 40px;
		display: none;
		position: absolute;
		border: 1.5px solid #09bd71;
		background: white;
		padding: 0 10px;
		left: -108px;
		top: 35px;
		border-radius: 4px;
		z-index: 10;
	}

	.pitch_slider {
		left: -108px;
		top: 35px;
	}

	.FontColorsOutlined svg {
		color: #1890ff;
	}

	.FontColorsOutlined {
		position: absolute;
		top: -14px;
		font-size: 20px;
		left: 121px;
		right: 0;
		bottom: 0;
		width: fit-content;
		height: fit-content;
	}

	.set_button>span {
		margin-top: -6px;
		margin-left: -6px;
	}

	.style_button {
		margin: auto;
		background: white;
		color: #09bd71;
		border-radius: 5px;
		width: 45px;
		height: 17px;
		display: flex;
		margin-left: 10px;
		font-size: 12px;
		font-weight: 400;
		line-height: 17px;
		border: 1px solid #09bd71;
	}

	.style_button span {
		margin: auto;
		margin-top: -1px;
	}

	.low_style {
		margin: auto;
		font-size: 12px;
		margin-right: 5px;
	}

	.height_style {
		margin: auto;
		font-size: 12px;
		margin-left: 5px;
	}

	.local_rate {
		position: relative;
	}

	.local_rate:hover .rate_slider {
		display: flex;
	}

	.local_volume {
		position: relative;
	}

	.local_volume:hover .volume_slider {
		display: flex;
	}

	.style_button:hover {
		color: #37ca8c;
		border: 1px solid #37ca8c;
	}

	.con:empty:after {
		content: "请输入配音内容...";
		font-size: 14px;
		font-weight: 400;
		color: #acacac;
		line-height: 20px;
		transform: translateY(100%);
		-webkit-transform: translateY(100%);
		-moz-transform: translateY(100%);
		-o-transform: translateY(100%);
	}

	.read_value {
		border-top: 1px solid rgb(229, 229, 229);
		margin-bottom: 2px;
	}
	.mbm_audio_box {
		padding: 10px 0;
		width: 100%;
		height: auto;
		text-align: center;
	}
	.mbm_audio_playerBtn {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		margin-bottom: 30px;
		margin: 0 30px 0 0;
	}
	
	.mbm_audio_playerBtn>span {
		font-size: 58px;
		top: 50%;
		left: 50%;
		color: #167FE8;
	}
	
	.mbm_playerBtn {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		border-color: #09bd71;
		background-color: #09bd71;
		box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);
	}
	.mbm_audio_size {
		font-size: 12px;
		color: rgba(0, 0, 0, 0.7);
		line-height: 1;
	}
	.mbm_progress_box {
		box-sizing: border-box;
		height: 100%;
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	
	.mbm_progress_box>span {
		margin: 0 10px;
	}
	
	.text_hu1x812_slider {
		flex: 1;
		width: 240px;
	}
	.mbm_audio_timeBox {
		display: flex;
		align-items: center;
		font-size: 12px;
		color: rgba(0, 0, 0, 0.7);
		margin: 0 40px;
	}
	.audio_style{
		width: 100%;
		height: 100%;
		text-align: center;
		background: rgba(0, 0, 0, 0.05);
		position: absolute;
		z-index: 20;    
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
	}
	.handle__icon__img__jushx{
		width: 40px;
		height: 27px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.handle__icon__img__jushx img{
		width: 100%;
		height: auto;
	}
	.handle__icon__img__jushx.py img{transform: scale(0.84);}
	.handle__icon__img__jushx.number img{transform: scale(0.85);}
	.handle__icon__img__jushx.volume img{transform: scale(0.78);}
	.handle__icon__img__jushx.rate img,.handle__icon__img__jushx.pitch img{transform: scale(0.52);}
	/* .handle__icon__img__jushx.number img{transform: scale(0.85);} */
	
/* 	.main-options-item img {
		width: 40px;
		height: 27px;
		object-fit: contain;
		margin: 0 auto;
	} */
	.py_jpg{
		background-image: url(/img/texttospeech/icon-annotaion@2x.png);
		background-size: contain;
		height: 27px;
		width: 37px;
	}
	.number_jpg{
		background-image: url(/img/texttospeech/icon-number@2x.png);
		background-size: 30px 27px;
		height: 27px;
		width: 30px;
	}
	.punct_jpg{
		background-image: url(/img/texttospeech/icon-segmentation@2x.png);
		background-size: 40px 30px;
		height: 27px;
		width: 40px;
	}
	.contin_jpg{
		background-image: url(/img/texttospeech/icon-continuity@2x.png);
		background-size: 40px 30px;
		height: 27px;
		width: 40px;
	}
	.pasue_jpg{
		background-image: url(/img/texttospeech/icon-pause@2x.png);
		background-size: 40px 30px;
		height: 27px;
		width: 40px;
	}
	.volume_jpg{
		background-image: url(/img/texttospeech/icon-volume@2x.png);
		background-size: 32px 32px;
		height: 27px;
		width: 30px;
		margin: auto;
	}
	.rate_jpg{
		background-image: url(/img/texttospeech/icon-speed@2x.png);
		background-size: contain;
		height: 23px;
		width: 24px;
		margin:auto;
		margin-top: 4px;
	}
	.pitch_jpg{
		background-image: url(/img/texttospeech/icon-intonation@2x.png);
		background-size: contain;
		height: 25px;
		width: 25px;
		margin:auto;
		margin-top: 4px;
	}
	>>>.ant-slider-track{
		background-color: #09bd71;
	}
	>>>.ant-slider:hover .ant-slider-track{
		background-color: #37ca8c;
	}
	>>>.ant-slider-handle{
		border: solid 2px #09bd71;
	}
	>>>.ant-slider-handle:focus{
		border-color: #37ca8c;
	}
	>>>.ant-slider-handle:hover{
		border-color: #37ca8c;
	}
	>>>.ant-tabs-nav .ant-tabs-tab-active{
		color: #09bd71;
	}
	>>>.ant-tabs-nav .ant-tabs-tab:hover{
		color: #37ca8c;
	}
	>>>.ant-tabs-ink-bar{
		background-color: #09bd71;
	}
	>>>.ant-select:not(.ant-select-disabled):hover .ant-select-selector{
		border-color: #09bd71;
	}
	>>>.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
		border-color: #09bd71;
	}
	>>>.ant-btn-primary{
		background: #09bd71;
		border-color: #09bd71;
	}
	>>>.ant-btn:hover, .ant-btn:focus{
		color: #09bd71;
		background: #fff;
		border-color: #09bd71;
	}
	>>>.ant-btn-primary:hover, .ant-btn-primary:focus{
		background: #37ca8c;
		border-color: #37ca8c;
		color: #fff;
	}
	>>>.ant-spin-dot-item{
		background-color :#09bd71;
	}
	>>>.ant-spin{
		color: #09bd71;
	}
	>>>.ant-slider-step{
		background: #00000029;
	}
</style>
